import React, { Component } from "react";

import {
  AuthenticatedRoute,
  PageContainer,
  PageContent,
  LeftsideMenu
} from "../../../components";

import { Spinner } from "../../../components/widgets";
import TagAdminList from './tags-admin-list';

import { connect } from "react-redux";

import {
  resetAllMessages,
  fetchAndLoadTagList
} from "../../../actions";

import {
  TAG_LIST_ROUTE,
  TAG_CREATE_ROUTE,
  TAG_EDIT_ROUTE,
  TAG_DELETE_ROUTE
} from "../../../constants/routes";

import {
  ROLE_SYSTEM_ADMINISTRATOR
} from "../../../constants";

import CreateTagModal from './create-tag-modal';
import EditTagModal from './edit-tag-modal';
import DeleteTagModal from './delete-tag-modal';

import "./style.scss";

const mapStateToProps = (state, props) => ({
  isLoading: state.tags.list.isLoading
});

class Tags extends Component {
  componentDidMount() {
    this.props.resetAllMessages();
    this.props.fetchAndLoadTagList(true);
  }

  componentWillUnmount() {
    this.props.resetAllMessages();
  }

  render() {
    const { isLoading } = this.props;
    return (
      <PageContainer className="tag-management">
        <AuthenticatedRoute
          path={TAG_CREATE_ROUTE}
          component={CreateTagModal}
          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
        />
        <AuthenticatedRoute
          path={TAG_EDIT_ROUTE}
          component={EditTagModal}
          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
        />
        <AuthenticatedRoute
          path={TAG_DELETE_ROUTE}
          component={DeleteTagModal}
          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
        />
        <LeftsideMenu path={TAG_LIST_ROUTE} />
        <PageContent>
          {isLoading ? <Spinner /> : <TagAdminList /> }
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    resetAllMessages,
    fetchAndLoadTagList
  }
)(Tags);
