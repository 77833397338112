import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROOT_ROUTE, UNREGISTERED_ROUTE, PROFILE_EDIT_ROUTE } from "../../constants/routes";
import { connect } from "react-redux";

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.session.currentUser
  };
};

// This component is used to prevent logged out or unregistered users from accessing any
// routes that are _not_ expecting the user to have an active session.
// Currently this is just the login form route.
class UnauthenticatedRoute extends Component {

  get isAuthenticated() {
    const { currentUser } = this.props;
    return Boolean(currentUser);
  }

  get isRegistered() {
    const { currentUser } = this.props;
    return Boolean(this.isAuthenticated && currentUser.role);
  }

  get isNameSet() {
    const { currentUser } = this.props;
    let firstName = currentUser["custom:preferred_first_name"];
    let lastName = currentUser["custom:preferred_last_name"];
    return Boolean(this.isAuthenticated && firstName && lastName);
  }  

  targetComponent(props) {
    const { component: Component } = this.props;

    if (!this.isAuthenticated) {
      return Component ? <Component {...props} /> : null;
    }

    if (!this.isNameSet) {
      return <Redirect to={{pathname: PROFILE_EDIT_ROUTE}} />
    }

    if (!this.isRegistered) {
      return <Redirect to={{pathname: UNREGISTERED_ROUTE}} />
    }

    return <Redirect to={{pathname: ROOT_ROUTE}} />;
  }

  render() {
    // destructuring some extra variables to clean up ...rest a bit
    const { component, currentUser, children, ...rest } = this.props;
    return (
      <Route {...rest} render={props => this.targetComponent(props) }>
        {children}
      </Route>
    );
  }
}

export default connect(mapStateToProps)(UnauthenticatedRoute);
