/** @module api/issues
 * @desc Issues related api functions
 */

import { gqlQuery } from './';

import {
  ISSUE_LIST_ERROR,
  SINGLE_ISSUE_ERROR,
  GQL_NULL_RESPONSE_ERROR,
  CREATE_ISSUE_ERROR,
  UPDATE_ISSUE_ERROR,
  PREVIOUS_VERSION_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import {
  BATCH_CREATE_RECORD_TAG_MUTATION,
  BATCH_DELETE_RECORD_TAGS_MUTATION
} from './tags';

import {
  isDev
} from '../utils';

const LIST_ISSUES_QUERY = `
query listIssues {
  listRecords (limit: 10000) {
    items {
      id,
      title,
      published,
      category {
        id,
        name
      }
    }
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to fetch the list of issues
 * @function
 * @returns {Promise<issues[]|errors[]>}
 */
export const fetchIssueList = () => gqlQuery(LIST_ISSUES_QUERY)
  .then(res => {
    if (!res.listRecords.items) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.listRecords.items
  })
  .catch(res => {
    const parsedErrors = res.errors.map(error => {
      switch (error.errorType) {
        default:
          return makeGenericErrorMessage({context: 'issue list', error});
      }
    });
    parsedErrors.unshift(ISSUE_LIST_ERROR);
    throw(parsedErrors);
  });

if (isDev()) {
  window.fetchIssueList = () => {
    fetchIssueList().then(console.log, console.log);
  };
}

const GET_SINGLE_ISSUE_QUERY = `
query GetRecord ($id: ID!) {
  getRecord(id: $id) {
    agreement_language
    guidance
    id
    published
    rationale
    title
    campusGuidance {
      items {
        id
        guidance
        status
        campus {
          id
          long_name
          short_name
        }
      }
    }
    category {
      id
      name
    }
    versions(limit: 10000) {
      items {
        id
        updated_at
        updated_by
      }
    }
    tags(limit: 10000) {
      items {
        id
        tag {
          id
          name
        }
      }
    }
  }
}
`;


/**
 * Makes an authed call to the graphql endpoint to fetch the data for a single issue
 * @function
 * @param {string} issueId
 * @returns {Promise<issue|errors[]>}}
 */
export const fetchSingleIssue = issueId => gqlQuery(GET_SINGLE_ISSUE_QUERY, { id: issueId })
  .then(res => {
    if (!res.getRecord) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.getRecord
  })
  .catch(res => {
    const parsedErrors = res.errors.map(error => {
      switch (error.errorType) {
        default:
          return makeGenericErrorMessage({context: 'issue', error});
      }
    });
    parsedErrors.unshift(SINGLE_ISSUE_ERROR);
    throw(parsedErrors);
  });

if (isDev()) {
  window.fetchSingleIssue = issueId => {
    fetchSingleIssue(issueId).then(console.log, console.log);
  };
}

const GET_PREVIOUS_VERSION_QUERY = `
  query GetRecordVersion($id: ID!) {
    getRecordVersion(id: $id) {
      id,
      title,
      guidance,
      rationale,
      agreement_language,
      updated_at,
      updated_by,
      record {
        id
      }
    }
  }

`;

export const fetchPreviousVersion = versionId =>
  gqlQuery(GET_PREVIOUS_VERSION_QUERY, { id: versionId })
    .then(res => {
      if (!res.getRecordVersion) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.getRecordVersion;
    })
    .catch(res => {
      const parsedErrors = res.errors.map(error => {
        switch(error.errorType) {
          default:
          return makeGenericErrorMessage({context: 'issue version', error});
        }
      });
      parsedErrors.unshift(PREVIOUS_VERSION_ERROR);
      throw(parsedErrors);
    });

const CREATE_ISSUE_MUTATION = `
  mutation CreateIssue ($title: String!, $categoryId: ID!) {
    createRecord(input: {
      title: $title,
      published: false,
      recordCategoryId: $categoryId,
      guidance: "Please add some guidance",
      rationale: "Please add some rationale",
      agreement_language: "Please add some agreement language"
    }) {
      id
    }
  }
`;

export const createIssue = (issueData) =>
  gqlQuery(CREATE_ISSUE_MUTATION, { title: issueData.title, categoryId: issueData.categoryId})
    .then(res => {
      if (!res.createRecord) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.createRecord
    })
    .catch(res => {
      const parsedErrors = res.errors.map(error => {
        switch (error.errorType) {
          default:
            return makeGenericErrorMessage({context: 'create issue', error});
        }
      });
      parsedErrors.unshift(CREATE_ISSUE_ERROR);
      throw(parsedErrors);
    });

if (isDev()) {
  window.createIssue = issueData => {
    createIssue(issueData).then(console.log, console.log);
  };
}

const CREATE_ISSUE_VERSION_MUTATION = `
mutation CreateIssueVersion ($id: ID!, $title: String!, $guidance: String!, $rationale: String!, $agreement_language: String!) {
  createRecordVersion(input: {
    recordVersionRecordId: $id,
    title: $title,
    guidance: $guidance,
    rationale: $rationale,
    agreement_language: $agreement_language
  }) {
    id
  }
}
`;

const UPDATE_ISSUE_MUTATION = `
mutation UpdateIssue ($id: ID!, $published: Boolean!, $categoryId: ID!, $title: String!, $guidance: String!, $rationale: String!, $agreement_language: String!) {
  updateRecord(input: {
    id: $id,
    published: $published,
    recordCategoryId: $categoryId,
    title: $title,
    guidance: $guidance,
    rationale: $rationale,
    agreement_language: $agreement_language,
  }) {
    id,
    title,
    published,
    agreement_language,
    guidance,
    rationale,
    category {
      id,
      name
    },
    tags(limit: 10000) {
      items {
        id
        tag {
          id
          name
        }
      }
    },
    versions (limit: 10000) {
      items {
        id,
        updated_at,
        updated_by
      }
    },
    campusGuidance {
      items {
        id,
        guidance,
        status,
        campus {
          id,
          short_name,
          long_name
        }
      }
    },
  }
}
`;

export const updateIssue = (issueData) => {
  const updateData = {
    id: issueData.id,
    title: issueData.title,
    published: Boolean(issueData.published),
    // we want to use the recordCategoryId if we have one, but fall back to the issue's current
    // category's id when we don't
    categoryId: issueData.recordCategoryId || issueData.category.id,
    guidance: issueData.guidance,
    rationale: issueData.rationale,
    agreement_language: issueData.agreement_language
  };

  // check if we have tag stuff to change
  const tagDeleteData = issueData.tags.items ? issueData.tags.items.map(tag => tag.id) : [];
  const tagCreateData = issueData.tagIds ? issueData.tagIds.map(id => ({
    recordTagRecordId: issueData.id,
    recordTagTagId: id
  })) : [];

  // if we do push the promises onto an array
  const tagMutations = [];
  if (issueData.updateTags && tagDeleteData.length) {
    tagMutations.push(gqlQuery(BATCH_DELETE_RECORD_TAGS_MUTATION, {ids: tagDeleteData}));
  }

  if (issueData.updateTags && tagCreateData.length) {
    tagMutations.push(gqlQuery(BATCH_CREATE_RECORD_TAG_MUTATION, {tags: tagCreateData}));
  }

  // resolve tag promises (if any) then handle the normal issue update stuff.
  // The tag promises need to be resolved first otherwise the issue data returned by the
  // update issue mutation may have stale data due to race conditions
  return Promise.all(tagMutations).then(() => Promise.all([
    gqlQuery(UPDATE_ISSUE_MUTATION, updateData),
    gqlQuery(CREATE_ISSUE_VERSION_MUTATION, updateData)
  ])).then(res => {
    const issue = res[0];
    if (!issue.updateRecord) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return issue.updateRecord
  }).catch(res => {
      const combinedErrors = res.reduce((errors, item) => errors.concat(item), [])

      const parsedErrors = combinedErrors.map(error => {
        switch (error.errorType) {
          default:
            return makeGenericErrorMessage({context: 'update issue', error});
        }
      });
      parsedErrors.unshift(UPDATE_ISSUE_ERROR);
      throw(parsedErrors);
    });
};

if (isDev()) {
  window.updateIssue = issueData => {
    updateIssue(issueData).then(console.log, console.log);
  };
}
