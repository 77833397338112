/**
 * @module selectors/campuses
 * @desc selectors for campus data
 */

import { createSelector } from 'reselect';

const campusList = state => state.campuses.data;

/**
 * Selector to create a sorted list of campuses for use in the add users modal
 * @function
 * @param {object} state
 * @returns {array.<object>} sorted campus list
 */
export const getCampusListSelectData = createSelector(
  [campusList],
  campuses => campuses
    .sort((a, b) => (a.long_name < b.long_name) ? -1 : 1)
    .map(campus => ({name: campus.long_name, value: campus.id}))
);

export const getCampusById = id => createSelector(
  [campusList],
  campuses => campuses.find(campus => campus.id === id)
);
