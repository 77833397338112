/**
 * @module api
 * @desc Non-specific api functions
 */

import { getCurrentCognitoUserTokens } from './cognito';
import { GRAPHQL_ENDPOINT } from '../config/awsConfig';

export * from './cognito';
export * from './users';
export * from './campuses';
export * from './categories';
export * from './issues';
export * from './campus-guidance';
export * from './search';
export * from './tags';

/**
 * A wrapped fetch method that inserts the necessary headers for making authed api calls.
 * It also examines the response to determine if there is error data in a 200 response.
 * Finally, it attempts to parse the data and return a js object version.
 * @function
 * @param {string} url the url of the target endpoint
 * @param {object} [params] any additional fetch params (custom headers, method, body, etc...)
 * @returns {Promise<data|errors[]>}
 */
export const authFetch = (url, params = {}) =>  {
  // TODO error messages/codes
  return getCurrentCognitoUserTokens().then(({idToken, accessToken}) => {
    if (params.headers) {
      params.headers.append('Authorization', idToken);
      params.headers.append('Access-Token', accessToken);
      params.headers.append('Content-Type', 'application/json');
    } else {
      params.headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': idToken,
        'Access-Token': accessToken
      });
    }

    params.credentials = 'omit';

    const request = new Request(url, params);

    return fetch(request)
      .then(res => {
        // if we have a bad error code and no body content then bail out here
        if (!(res.body || res.ok)) {
          return Promise.reject(res);
        }

        return res.json();
      })
      .then(json => {
        if (
          json.errorMessage ||
          json.errors ||
          (json.statusCode >= 400)
        ) {
          return Promise.reject(json);
        }

        if (json.data) {
          return json.data;
        }

        if (json.body) {
          return JSON.parse(json.body);
        }

        return json;
      });
  });
};


/**
 * Makes an authed request to the graphql endpoint
 * @function
 * @param {string} query gprahql query string
 * @param {object|null} [variables] graphql variables. Can pass null if params are needed but variables are not
 * @param {object} [params] any additional fetch params (custom headers, etc...)
 * @returns {Promise<data|errors[]>}
 */
export const gqlQuery = (query, variables = {}, params = {}) => {
  // need to do this in case we do something like gqlQuery("...", null, { ... });
  variables = (typeof variables === 'object') ? variables : {};
  const gqlParams = {
    ...params,
    method: 'POST',
    body: JSON.stringify({query, variables})
  };

  return authFetch(GRAPHQL_ENDPOINT, gqlParams).catch(error => {
    if (error instanceof TypeError) {
      // if it isn't a gql error then throw it in an array so we can deal with it later
      const errorObject = {
        errors: [{...error}]
      };
      throw(errorObject);
    } else {
      throw(error)
    }
  });
};

export const fakeFetch = (duration = 1000, res = {}) => new Promise(resolve => {
  setTimeout(() => resolve(res), duration);
});
