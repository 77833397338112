import React, { Component, createRef } from "react";
import { makeClassName } from '../../utils';

export default class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.focusRef = createRef();
    this.listRef = createRef();
    this.pillBottleRef = createRef();

    this.state = {
      filterText: '',
      selectedTags: this.props.issueTags || [],
      filteredTagList: [],
      listIdx: -1,
      listOpen: false,
      addTagButtonText: 'Assign Tag'
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTags.length > prevState.selectedTags.length) {
      this.scrollToLastPill();
    }
  }

  tagLookup(tagName) {
    return this.props.tagList
      .find(tag => tag.name === tagName);
  }

  // get a copy of the taglist for manipulation
  get allTags() {
    return Array.from(this.props.tagList);
  }

  get selectedTags() {
    return Array.from(this.state.selectedTags);
  }

  addTag = () => {
    const tagText = this.state.filterText.trim();
    if (!tagText || tagText.length === 0) {
      return;
    }
    let tag = this.tagLookup(tagText);
    if (!tag) {
      // if the tag doesn't exist, create it in the DB
      this.props.addNewTag({name: tagText}).then(tag => {
        this.props.storeNewTag(tag);
        this.updateState(tag);
      })
      return;
    }
    // don't add duplicates
    if (this.state.selectedTags.find(t => t.id === tag.id)) {
      return;
    }

    this.updateState(tag);
  }

  updateState = tag => {
    const tags = this.selectedTags;
    tags.push(tag);
    this.setState({
      selectedTags: tags,
      filterText: '',
      listOpen: false,
      filteredTagList: [],
      addTagButtonText: 'Assign Tag'
    });
  }

  get highlightedTag() {
    const {
      listOpen,
      listIdx,
      filteredTagList
    } = this.state;
    if (!(listOpen && listIdx > -1)) {
      return null;
    }

    return filteredTagList[listIdx];
  }

  isTagAlreadySelected = tagName => {
    return this.state.selectedTags.find(tag => tag.name.trim() === tagName.trim());
  }

  removeTag(tag) {
    const tagList = this.selectedTags;
    const idx = tagList.findIndex(t => t.id === tag.id);

    if (idx > -1) {
      tagList.splice(idx, 1);
    }
    this.setState({selectedTags: tagList})
  }

  openList = () => {
    this.setState({
      listOpen: true,
      listIdx: -1
    });
  }

  closeList = () => {
    this.setState({listOpen: false});
  }

  setFilterText = (text, focusAndClose = false) => {
    const filteredTagList = this.allTags
      .filter(({name}) => name.toLowerCase()
        .includes(text.toLowerCase()) &&
        !this.isTagAlreadySelected(name))

    const addButtonText = (text && (!filteredTagList.length)) ? 'Add New Tag' : 'Assign Tag';

    this.setState({
      filterText: text,
      filteredTagList,
      addTagButtonText: addButtonText
    });

    if (!this.state.listOpen && text.trim().length) {
      this.openList();
    } else if (!text.trim().length) {
      this.closeList();
    }

    if (focusAndClose) {
      this.focusRef.current.focus();
      if (this.state.listOpen) {
        this.closeList();
      }
    }
  }

  incrementListIdx() {
    const {
      listOpen,
      listIdx,
      filteredTagList,
      filterText
    } = this.state;

    // empty list
    if (!filteredTagList.length) {
      return;
    }

    // closed list
    if (!listOpen && filterText.trim().length) {
      this.openList();
      return
    }

    // open list, nothing selected
    if (listIdx < 0) {
      this.setState({listIdx: 0});
      return;
    }

    // at end of list
    if (listIdx >= filteredTagList.length - 1) {
      this.setState({listIdx: 0});
      this.scrollToTag(0);
      return;
    }

    // anywhere else in list
    this.setState({listIdx: listIdx + 1});
    this.scrollToTag(listIdx + 1);
  }

  decrementListIdx() {
    const {
      listOpen,
      listIdx,
      filteredTagList
    } = this.state;

    // empty list
    if (!(filteredTagList.length && listOpen)) {
      return;
    }

    if (filteredTagList.length && !listOpen) {
      this.setState({
        listOpen: true,
        listIdx: filteredTagList.length - 1
      });
      return;
    }

    // at beginning of list
    if (listIdx <= 0) {
      this.setState({
        listIdx: filteredTagList.length - 1
      });
      this.scrollToTag(filteredTagList.length - 1);
      return
    }

    // elsewhere in list
    this.setState({ listIdx: listIdx - 1 });
    this.scrollToTag(listIdx - 1);
  }

  isTagVisible(idx) {
    const {
      listOpen
    } = this.state;

    if (this.listRef.current && listOpen) {
      const list = this.listRef.current;
      const tag = list.children[idx];

      const tagTop = tag.offsetTop;
      const tagBottom = tagTop + tag.clientHeight;

      const listTop = list.scrollTop;
      const listBottom = listTop + list.clientHeight;

      return (tagTop >= listTop) && (tagBottom <= listBottom);
    }
  }

  scrollToTag(idx) {
    const {
      listOpen
    } = this.state;


    if (this.listRef.current && listOpen) {
      if (!this.isTagVisible(idx)) {
        const listElement = this.listRef.current;
        const tagElement = listElement.children[idx];
        if (tagElement) {
          listElement.scrollTop = tagElement.offsetTop - 20;
        }
      }
    }
  }

  scrollToLastPill() {
    if (this.pillBottleRef.current) {
      const bottle = this.pillBottleRef.current;
      const pill = bottle.lastElementChild;
      if (pill) {
        bottle.scrollTop = pill.offsetTop;
      }
    }
  }

  get canAddTag() {
    const filterText = this.state.filterText;
    return !this.isTagAlreadySelected(filterText);
  }

  get showList() {
    const {
      filteredTagList,
      listOpen
    } = this.state;

    return filteredTagList.length && listOpen;
  }

  handleListNavigation = e => {
    switch (e.key) {
      case "Escape":
        this.setFilterText('', true);
        break;

      case "Enter":
        e.preventDefault();
        // using { } to start a new block so we can safely declare a variable in a switch case
        {
          const selectedTag = this.state.filteredTagList[this.state.listIdx];
          if (this.state.listOpen && selectedTag) {
            this.setFilterText(selectedTag.name, true);
          } else {
            this.addTag();
          }
        }
        break;

      case "ArrowDown":
        e.preventDefault();
        this.incrementListIdx();
        break;

      case "ArrowUp":
        e.preventDefault();
        this.decrementListIdx();
        break;

      case "ArrowLeft":
      case "ArrowRight":
      case "Home":
      case "End":
        this.setState({ listIdx: -1 });
        break;
      default:
        // nothing
    }
  }

  get tagIdString() {
    return this.state.selectedTags.map(tag => tag.id).join(",");
  }

  render() {
    const {
      filterText,
      selectedTags,
      filteredTagList,
      listIdx,
      listOpen,
      disabled,
      addTagButtonText
    } = this.state;

    return (
      <div className="tag-selector-container">
        <label id="tag-selector-label" htmlFor="tag-name-filter">Tags</label>

        <ul ref={this.pillBottleRef} className="tag-pillbottle">
          {
            selectedTags.map(tag => (
              <li className="tag-pill" key={tag.id}>
                <span>{tag.name}</span>
                <button onClick={() => this.removeTag(tag)}>X
                  <span className="sr_hide">remove {tag.name}</span></button>
              </li>
            ))
          }
        </ul>
        <input type="hidden" name="tagids" value={this.tagIdString}/>

        <div className="tag-picker-container">
          <div
            // eslint-disable-next-line
            role="combobox"
            aria-haspopup="listbox"
            aria-owns="tag-list"
            aria-expanded={listOpen}
            className="tag-dropdown-container"
            onBlur={this.closeList}
          >
            <input
              id="tag-name-filter"
              autoComplete="off"
              disabled={disabled}
              aria-autocomplete="list"
              aria-controls="tag-list"
              aria-activedescendant={this.highlightedTag ? this.highlightedTag.name : "" }
              type="text"
              value={filterText}
              ref={this.focusRef}
              onFocus={() => {
                if (!listOpen && filteredTagList.length) {
                  this.openList();
                }
              }}
              onChange={e => {
                e.preventDefault();
                this.setFilterText(e.target.value);
              }}

              onKeyDown={this.handleListNavigation}
            />

          {
            (Boolean(this.showList)) &&
              <ul
                role="listbox"
                id="tag-list"
                ref={this.listRef}
                aria-labelledby="tag-selector-label"
                className="tag-list"
                onKeyDown={this.navigateThroughTagList}
              >
                {
                  filteredTagList.map((tag, idx) => (
                    <li
                      role="option"
                      aria-selected={idx === listIdx}
                      className={makeClassName(
                        "tag-list-item",
                        idx === listIdx ? "selected" : ""
                      )}
                      id={tag.name + "-tag"}
                      key={tag.id}
                      onMouseDown={e => e.preventDefault()} // mouse event prevents bluring
                      onClick={e => {
                        e.preventDefault();
                        this.setFilterText(tag.name, true);
                      }}
                    >
                      {tag.name}
                    </li>
                  ))
                }
              </ul>
          }
        </div>
        <button
          disabled={!this.canAddTag || disabled}
          className="add-tag-button"
          onClick={e => {
            e.preventDefault();
            this.addTag();
          }}
        >
          {addTagButtonText}
        </button>
      </div>
    </div>
    );
  }
}
