export const LOAD_USER = 'LOAD_USER';

export const ADD_USER = 'ADD_USER';

export const SET_USER_LIST_LOADING_STATUS = 'SET_USER_LIST_LOADING_STATUS';
export const LOAD_USER_LIST = 'LOAD_USER_LIST';

export const SET_USER_ACTION_SUCCESS_MESSAGE = 'SET_USER_ACTION_SUCCESS_MESSAGE';
export const SET_USER_ACTION_ERROR = 'SET_USER_ACTION_ERROR';
export const SET_USER_ACTION_LOADING_STATUS = 'SET_USER_ACTION_LOADING_STATUS';
export const SET_USER_ACTION_UPDATING_STATUS = 'SET_USER_ACTION_UPDATING_STATUS';

