import React from "react";
import wordmark from "./wordmark.png";
import { APP_TITLE } from "../../constants";


const LoginWrapper = props => {

    return (
      <div className="login-form-container">
        <div className="login-form">
          <div className="login-text-container">
            <img
              src={wordmark}
              className="wordmark"
              alt="University of California"
            />
            <h1>{APP_TITLE}</h1>
          </div>
          { props.children }
        </div>
      </div>
    );
}

export default LoginWrapper;
