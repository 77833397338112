import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, RichTextEditor, Spinner } from "../../components/widgets";
import IssueSectionContainer from "./issue-section-container";

import {
  setNavSectionVisible,
  setSectionEditStatus
} from "../../actions";

const mapStateToProps = (state, props) => {
  return {
    visibleSection: state.issues.currentIssue.visibleSection
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNavSectionVisible: id => dispatch(setNavSectionVisible(id)),
    setSectionEditStatus: (section, status) => dispatch(setSectionEditStatus(section, status))
  };
};

class IssueSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: props.startInEditMode,
    };
  }
  // if we get new data props while in edit mode then we should
  // switch back to view mode. This should only ever happen when we
  // save our data and it gets updated in the store.
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.content !== this.props.content) ||
      (this.props.customData !== prevProps.customData))
    {
      this.setState({editMode: false});
    }

    if (this.props.field) {
      this.props.setSectionEditStatus(this.props.field, this.state.editMode);
    }
  }

  getEditor = ref => {
    this.editor = ref;
  }

  onSave = e => {
    // if we try to save without changing anything then just close the editor
    if (this.editor && this.editor.isNotDirty) {
      this.cancel();
      return;
    }

    const content = this.editor && this.editor.getContent();
    const rawText = this.editor && this.editor.getContent({format: 'text'});
    if (typeof this.props.onSave === "function") {
      if (this.props.field) {
        this.props.onSave({[this.props.field]: content, rawText});
      } else {
        this.props.onSave({ content, rawText });
      }
    }
  };

  edit = () => {
    this.setState({ editMode: true });
  };

  cancel = () => {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    this.setState({ editMode: false });
  };

  render() {
    const { startInEditMode, init, id, title, content, canEdit, isCollapsible, shouldLockForm } = this.props;
    const shouldShowEditButton = !this.state.editMode && canEdit;
    const shouldShowCollapseButton = isCollapsible && !this.state.editMode;

    return (
      <IssueSectionContainer
        title={title}
        id={id}
        editAction={this.edit}
        showCollapseButton={shouldShowCollapseButton}
        showEditButton={shouldShowEditButton}
        startCollapsed={isCollapsible && !startInEditMode}
      >
        <RichTextEditor
          initialValue={content}
          editMode={this.state.editMode}
          disabled={shouldLockForm}
          height={500}
          getEditor={this.getEditor}
          init={init}
        />

      {
        this.state.editMode &&
          <div className="action-btns">
            {
              shouldLockForm ?
                <Fragment>
                  <br />
                  <Spinner />
                </Fragment>
                :
                <Fragment>
                  <Button className="btn save-section-button" onClick={this.onSave} >Save</Button>
                  <Button className="btn cancel-section-button" onClick={this.cancel} >Cancel</Button>
                </Fragment>
            }
          </div>
      }
      </IssueSectionContainer>
    );
  }
}

IssueSection.propTypes = {
  isCollapsible: PropTypes.bool,
  title: PropTypes.string,
  onSave: PropTypes.func,
  content: PropTypes.string,
  customData: PropTypes.any,
  canEdit: PropTypes.bool,
  id: PropTypes.string.isRequired,
  startCollapsed: PropTypes.bool,
  field: PropTypes.string,
  shouldLockForm: PropTypes.bool,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  startInEditMode: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueSection);
