import React, { Fragment, Component } from "react";
import IssueSection from "./issue-section";
import { Button, Spinner } from "../../components/widgets";
import {
  CAMPUS_GUIDANCE_STATUS_UNPUBLISHED,
  CAMPUS_GUIDANCE_STATUS_CAMPUS,
  CAMPUS_GUIDANCE_STATUS_ALL
} from "../../constants";

class CampusGuidance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creatingNewGuidance: false,
      status:
        (props.campusGuidance && props.campusGuidance.status) ||
        CAMPUS_GUIDANCE_STATUS_UNPUBLISHED,
      shouldSwitchToViewMode: false
    };
  }

  addCampusGuidanceWYSIWYGControls = editor => {
    editor.ui.registry.addMenuButton("campus-visibility", {
      text: "Campus Visibility",
      fetch: callback => {
        callback([
          {
            type: "menuitem",
            text: "Unpublished",
            icon:
              this.state.status === CAMPUS_GUIDANCE_STATUS_UNPUBLISHED
                ? "checkmark"
                : null,
            onAction: () => {
              editor.setDirty(true);
              this.setState({ status: CAMPUS_GUIDANCE_STATUS_UNPUBLISHED });
            }
          },
          {
            type: "menuitem",
            text: "Published only to my campus",
            icon:
              this.state.status === CAMPUS_GUIDANCE_STATUS_CAMPUS
                ? "checkmark"
                : null,
            onAction: () => {
              editor.setDirty(true);
              this.setState({ status: CAMPUS_GUIDANCE_STATUS_CAMPUS });
            }
          },
          {
            type: "menuitem",
            text: "Published to all campuses",
            icon:
              this.state.status === CAMPUS_GUIDANCE_STATUS_ALL
                ? "checkmark"
                : null,
            onAction: () => {
              editor.setDirty(true);
              this.setState({ status: CAMPUS_GUIDANCE_STATUS_ALL });
            }
          }
        ]);
      }
    });
  };

  onSave = ({ content, rawText }) => {
    const data = {};
    if (this.props.campusGuidance) {
      data.id = this.props.campusGuidance.id;
    } else {
      data.campus_id = this.props.campus.id;
    }
    data.status = this.state.status;
    data.guidance = content;
    data.rawText = rawText;

    if (typeof this.props.onSave === "function") {
      this.props.onSave(data);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.campusGuidance && this.props.campusGuidance) {
      this.cancelCreatingNewGuidance();
    }
  }

  openEditorToCreateNewGuidance = () => {
    this.setState({ creatingNewGuidance: true });
  };

  cancelCreatingNewGuidance = () => {
    // need this extra check so we don't set state when pressing cancel on an already created item
    if (this.state.creatingNewGuidance) {
      this.setState({ creatingNewGuidance: false });
    }
  };

  render() {
    const { shouldShowStatus, canEdit, campusGuidance, campus, shouldLockForm } = this.props;
    const init = {
      setup: this.addCampusGuidanceWYSIWYGControls,
      toolbar3: "campus-visibility"
    };

    let title = campus.long_name + " Guidance";
    if (shouldShowStatus && campusGuidance) {
      title += ` (visible to ${campusGuidance.status})`
    }

    return (
      <Fragment>
        {campusGuidance || this.state.creatingNewGuidance ? (
          <IssueSection
            id={campus.short_name + "-guidance"}
            title={title}
            canEdit={canEdit}
            startInEditMode={this.state.creatingNewGuidance}
            isCollapsible
            content={campusGuidance && campusGuidance.guidance}
            customData={campusGuidance && campusGuidance.status}
            shouldLockForm={shouldLockForm}
            onSave={this.onSave}
            onCancel={this.cancelCreatingNewGuidance}
            init={init}
          />
        ) : (
          <Fragment>
            {shouldLockForm ? (
              <Spinner />
            ) : (
              <div className="campus-button-wrapper">
                <Button
                  className="btn-add-guidance"
                  onClick={this.openEditorToCreateNewGuidance}
                >
                  Create Guidance for {campus.long_name}
                </Button>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default CampusGuidance;
