import { combineReducers } from 'redux';
import session from './session';
import categories from './categories';
import issues from './issues';
import users from './users';
import campuses from './campuses';
import messages from './messages';
import search from './search';
import tags from './tags';

export default combineReducers({
  session,
  categories,
  issues,
  users,
  campuses,
  messages,
  search,
  tags
});
