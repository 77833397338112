import React from "react";
import { Table } from "../../../components/widgets";
import { connect } from 'react-redux';
import UserButtons from './user-buttons';
import {
  getUserList,
  getCurrentUser
} from "../../../selectors";

import { isLocationAccessCoordinator } from "../../../utils";

const USER_LIST_COLUMNS = [
  {
    name: 'email',
    displayName: 'Email',
    displayTransformer: user => <a href={`mailto:${user.email}`}>{user.email}</a>,
    filter: (user, filterTerm) => user.email &&
      user.email.toLowerCase().includes(filterTerm.toLowerCase()),
    sort: (a, b) => a.email > b.email ? 1 : -1,
    defaultSort: true,
  },

  {
    name: 'firstName',
    displayName: 'First Name',
    displayTransformer: user => user.firstName,
    filter: (user, filterTerm) => user.firstName &&
      user.firstName.toLowerCase().includes(filterTerm.toLowerCase()),
    sort: (a, b) => {
      if (a.firstName && b.firstName) {
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1;
      }
      return a.firstName ? -1 : 1;
    },
  },
  {
    name: 'lastName',
    displayName: 'Last Name',
    displayTransformer: user => user.lastName,
    filter: (user, filterTerm) => user.lastName &&
      user.lastName.toLowerCase().includes(filterTerm.toLowerCase()),
    sort: (a, b) => {
      if (a.lastName && b.lastName) {
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1;
      }
      return a.lastName ? -1 : 1;
    },
  },

  {
    name: 'campus',
    displayName: 'Campus',
    displayTransformer: user => user.campus ? user.campus.short_name : "N/A",
    filter: (user, filterTerm) => user.campus &&
      user.campus.short_name.toLowerCase().includes(filterTerm.toLowerCase()),
    sort: (a, b) => {
      // we shouldn't actually have a user without a campus but it doesn't hurt to check
      if (!a.campus) {
        return 1;
      }

      if (!b.campus) {
        return -1;
      }

      if (a.campus.short_name === b.campus.short_name) {
        return a.email > b.email ? 1 : -1;
      }

      return a.campus.short_name > b.campus.short_name ? 1 : -1;
    },
  },

  {
    name: 'roleName',
    displayName: 'Role',
    filter: (user, filterTerm) => user.roleName && user.roleName.toLowerCase().includes(filterTerm.toLowerCase()),
    sort: (a, b) => {
      if (a.roleName === b.roleName) {
        return a.email > b.email ? 1 : -1;
      }

      return a.roleName > b.roleName ? 1 : -1;
    },
  },

  {
    name: 'action',
    displayName: 'Action',
    displayTransformer: UserButtons
  },
];

const mapStateToProps = (state, props) => ({
  data: getUserList(state),
  currentUser: getCurrentUser(state)
});

const UserList = props => {
  const { currentUser, data } = props;

  if (isLocationAccessCoordinator(currentUser)) {

    const emailSuffix = currentUser.email.split('@')[1];
    // using if here in case we have imporoper email data
    if (emailSuffix) {
      // grab the last two secctions of the domain
      const domain = emailSuffix.split('.').slice(-2).join('.');
      USER_LIST_COLUMNS[0].initialFilterValue = domain;
    }
  }

  return (
    <div className="admin-table">
      <Table
        caption="Users tables"
        columns={USER_LIST_COLUMNS}
        data={data}
        itemsPerPage={10}
      />
    </div>
  );
}

export default connect(
  mapStateToProps
)(UserList);

