import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

import { ROOT_ROUTE } from "../../constants/routes";
import { WordMark } from "../widgets";
import { SearchBar, Usermenu } from "../";

const Header = props => {
  let { children } = props;

  return (
    <header className="app-header" aria-live="polite">
      <div className="app-header-top-row" id="top">
        <div className="left">
          <WordMark />
          <Link to={ROOT_ROUTE}>
            <h2>Negotiator's Handbook</h2>
          </Link>
        </div>

        <Usermenu />
      </div>
      <div className="app-header-search-row">
        <SearchBar />
      </div>
      {children}
    </header>
  );
};

export default Header;
