import React, { Component, createRef } from "react";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { TAG_LIST_ROUTE } from "../../../constants/routes";

import { resetAllMessages, resetErrors } from "../../../actions";

import { connect } from "react-redux";
import history from "../../../history";

import { attemptToCreateTag } from "../../../actions";

const mapStateToProps = (state, props) => {
  return {
    shouldLockForm: state.tags.actions.isLoading,
    errors: state.messages.errors
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: tagData => dispatch(attemptToCreateTag(tagData)),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(TAG_LIST_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class CreateTag extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }

  componentDidMount() {
    this.props.resetMessages();
    this.focusRef.current.focus();
  }

  render() {
    const { hideModal, onSubmit, shouldLockForm, errors } = this.props;

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"ucop-form-editcategory"}>
          <h2>Create a new Tag</h2>
          <br />
          <ErrorList errors={errors} />
          <TextInput
            focusRef={this.focusRef}
            name="name"
            label="Tag Name*"
            type="text"
            required={true}
            disabled={shouldLockForm}
          />
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner light /> : "Save"}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTag);
