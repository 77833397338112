import React, { Component } from "react";

import {
  AuthenticatedRoute,
  PageContainer,
  PageContent,
  LeftsideMenu
} from "../../../components";

import { Spinner } from "../../../components/widgets";
import CategoryAdminList from './category-admin-list';

import { connect } from "react-redux";

import {
  resetAllMessages,
  fetchAndLoadCategoryList
} from "../../../actions";

import {
  CATEGORY_EDIT_ROUTE,
  CATEGORY_LIST_ROUTE,
  CATEGORY_CREATE_ROUTE
} from "../../../constants/routes";

import {
  ROLE_SYSTEM_ADMINISTRATOR
} from "../../../constants";

import EditCategoryModal from './edit-category-modal';
import CreateCategoryModal from './create-category-modal';


const mapStateToProps = (state, props) => ({
  isLoading: state.categories.list.isLoading
});

class Categories extends Component {
  componentDidMount() {
    this.props.resetAllMessages();
    this.props.fetchAndLoadCategoryList(true);
  }

  componentWillUnmount() {
    this.props.resetAllMessages();
  }

  render() {
    const { isLoading } = this.props;
    return (
      <PageContainer className="category-management">
        <AuthenticatedRoute
          path={CATEGORY_EDIT_ROUTE}
          component={EditCategoryModal}
          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
        />
        <AuthenticatedRoute
          path={CATEGORY_CREATE_ROUTE}
          component={CreateCategoryModal}
          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]}
        />
        <LeftsideMenu path={CATEGORY_LIST_ROUTE} />
        <PageContent>
          {isLoading ? <Spinner /> : <CategoryAdminList /> }
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    resetAllMessages,
    fetchAndLoadCategoryList
  }
)(Categories);
