import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '../widgets';
import { CATEGORY_ROUTE_PREFIX } from '../../constants/routes';

import './style.scss';

import { connect } from "react-redux";
import { fetchAndLoadCategoryList } from "../../actions";
import { getSortedCategoryList } from "../../selectors";

const mapStateToProps = (state, props) => {
  return {
    categories: getSortedCategoryList(state),
    isLoading: state.categories.list.isLoading
  };
};

class CategoryList extends Component {
  componentDidMount() {
    this.props.fetchAndLoadCategoryList();
  }

  render() {
    const { categoryId, categories, isLoading } = this.props;

    return (
      <div className="category-list">
        <h4>Categories</h4>
        {
          isLoading ?
            <Spinner />
              :
            <ul>
              {(categories).map((category) => (
                <li key={category.id}>
                  {category.id === categoryId ?
                    <span>{category.name}</span> :
                    <Link to={CATEGORY_ROUTE_PREFIX + category.id}>{category.name}</Link>
                  }
                </li>
              ))}
            </ul>
        }
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  { fetchAndLoadCategoryList }
)(CategoryList);
