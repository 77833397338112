export const APP_TITLE = 'Negotiator\'s Handbook';

export const ROLE_SYSTEM_ADMINISTRATOR = 'sys_admin';
export const ROLE_CAMPUS_ADMINISTRATOR = 'campus_admin';
export const ROLE_LOCATION_ACCESS_COORDINATOR = 'location_c';
export const ROLE_VIEWER = 'viewer';

export const ROLES = [
  {
    name: 'System Administrator',
    value: ROLE_SYSTEM_ADMINISTRATOR
  },
  {
    name: 'Campus Administrator',
    value: ROLE_CAMPUS_ADMINISTRATOR
  },
  {
    name: 'Location Access Coordinator',
    value: ROLE_LOCATION_ACCESS_COORDINATOR
  },
  {
    name: 'Viewer',
    value: ROLE_VIEWER
  }
];

export const ALL_ROLES = ROLES.map(role => role.value);

// campus guidance statuses
export const CAMPUS_GUIDANCE_STATUS_UNPUBLISHED = 'none';
export const CAMPUS_GUIDANCE_STATUS_ALL = 'all';
export const CAMPUS_GUIDANCE_STATUS_CAMPUS = 'campus';

// Search result types
export const TYPE_ISSUE = 'TYPE_ISSUE';
export const TYPE_CAMPUS_GUIDANCE = 'TYPE_CAMPUS_GUIDANCE';
export const TYPE_CATEGORY = 'TYPE_CATEGORY';
export const TYPE_TAG = 'TYPE_TAG';
