import React, { Component, createRef } from "react";
import PropTypes from "prop-types";

import { makeClassName } from "../../../utils";

import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";

const DEFAULT_TOOLBAR =
  "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink";
const TABLE_TOOLBAR =
  "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol";

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    this.setEditorRefInParent();
    this.focusOnEditor();
  }

  focusOnEditor = () => {
    if (!(this.ref.current && this.ref.current.editor)) {
      return;
    }
    const editor = this.ref.current.editor;
    // Normally we could use the 'auto_focus' property in our init object,
    // but since this is a react component we won't know the selector of the underlying
    // element that tinymce binds to until after init.
    // Instead we add an init eventhandler and this works for some reason
    // Taken from https://community.tiny.cloud/communityQuestion?id=90661000000IhC9AAK
    editor.on("init", () => {
      editor.focus();
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.editMode && !prevProps.editMode && this.ref.current) {
      this.focusOnEditor();
    }
    this.setEditorRefInParent();
  }

  setEditorRefInParent() {
    if (typeof this.props.getEditor === "function" && this.ref.current) {
      this.props.getEditor(this.ref.current.editor);
    }
  }

  render() {
    const { initialValue, editMode, height, className, disabled } = this.props;
    const init = this.props.init || {};
    return (
      <div className={makeClassName('ucop-rich-text-editor', editMode ? 'edit' : 'view', className)}>
        {
          editMode &&
          <Editor
            ref={this.ref}
            onEditorChange={this.updateContent}
            initialValue={initialValue}
            disabled={disabled}
            init={{
              branding: false,
              menubar: false,
              plugins: ["table", "lists", "link", "unlink"],
              toolbar1: DEFAULT_TOOLBAR,
              toolbar2: TABLE_TOOLBAR,
              height,
              ...init
            }}
          />
        }
        <div className="print-view" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(initialValue)}} />
      </div>
    );
  }
}

RichTextEditor.propTypes = {
  initialValue: PropTypes.string,
  editMode: PropTypes.bool,
  getEditor: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.number
};

export default RichTextEditor;
