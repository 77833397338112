/**
 * @module api/users
 * @desc Methods for making api calls related to user management (user sessions are handled by api/cognito)
 */

import { authFetch } from './';
import {
  UNABLE_TO_LOAD_LIST_ERROR,
  UNABLE_TO_UPDATE_USER_ERROR,
  UNABLE_TO_DELETE_USER_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';
import { USERS_ENDPOINT } from '../config/awsConfig';

/**
 * Makes an authed GET call to the users endpoint to retrieve a list of all NHB users
 * @function
 * @returns {Promise<userList[]|errors[]>}
 */
export const fetchUserList = () => authFetch(USERS_ENDPOINT)
  .then(res =>{
    return res.data
  })
  .catch(error => {
    // TODO respond to error info if we get that sorted on the backend
    const errors = [
      UNABLE_TO_LOAD_LIST_ERROR,
      makeGenericErrorMessage({context: 'user list', error})
    ];
    throw(errors);
  });

/**
 * Makes an authed PUT call to the users endpoint to update an NHB user
 * @function
 * @param {object} userData
 * @param {string} userData.email
 * @param {string} userData.firstname
 * @param {string} userData.lastname
 * @param {string} userData.campus
 * @param {string} userData.role
 * @returns {Promise<user|errors[]>}
 */
export const updateUser = userData => {
  const { id, firstname, lastname, campusid, role } = userData;

  return authFetch(USERS_ENDPOINT, {
    method: 'PUT',
    body: JSON.stringify({
      username: id,
      first_name: firstname,
      last_name: lastname,
      campus_id: campusid,
      role
    })
  }).catch(error => {
    const errors = [
      UNABLE_TO_UPDATE_USER_ERROR,
      makeGenericErrorMessage({context: 'update user', error})
    ];
    throw(errors);
  });
};


/**
 * Makes an authed DELTE call to the users endpoint to delete an NHB user
 * @function
 * @param {string} email
 * @returns {Promise<confirmation|errors[]>}
 */
export const deleteUser = id => {
  return authFetch(USERS_ENDPOINT, {
    method: 'DELETE',
    body: JSON.stringify({
      id
    })
  }).catch(error => {
    const errors = [
      UNABLE_TO_DELETE_USER_ERROR,
      makeGenericErrorMessage({context: 'delete user', error})
    ];
    throw(errors);
  });
};

export const getCurrentUserRole = () => {
  return authFetch(USERS_ENDPOINT + '/myrole').then(role => role.data);
}
