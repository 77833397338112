/**
 * @module api/tags
 * @desc Category related api functions.
 */

import { gqlQuery } from './';
import {
  TAG_LIST_ERROR,
  SINGLE_TAG_ERROR,
  TAG_CREATE_ERROR,
  TAG_UPDATE_ERROR,
  TAG_DELETE_ERROR,
  GQL_NULL_RESPONSE_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import {
  isDev
} from '../utils';

const LIST_TAGS_QUERY =`
query ListTags {
  listTags(limit: 10000) {
    items {
      id
      name
      records(limit: 1000) {
        items {
          record {
            id
            published
            title
            category {
              id
              name
            }
          }
        }
      }
    }
  }
}`;

/**
 * Makes an authed call to the graphql endpoint to retrieve a list of tags
 * @function
 * @returns {Promise<categories[]|errors[]>}
 */
export const fetchTagList = () => gqlQuery(LIST_TAGS_QUERY)
  .then(res => {
    if (!res.listTags.items) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.listTags.items
  })
  .catch(res => {
    const errors = [
      TAG_LIST_ERROR,
      makeGenericErrorMessage({context: 'tag list', error: res.errors})
    ];
    throw(errors);
  });

if (isDev()) {
  window.fetchTagList = () => {
    fetchTagList().then(console.log, console.log);
  };
}

const GET_SINGLE_TAG_QUERY = `
query GetTag ($id: ID!) {
  getTag(id: $id) {
    id
    name
    records(limit: 1000) {
      items {
        id
      }
    }
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to fetch the data for a single tag
 * @function
 * @param {string} tagId
 * @returns {Promise<issue|errors[]>}}
 */
export const fetchSingleTag = tagId => gqlQuery(GET_SINGLE_TAG_QUERY, { id: tagId })
  .then(res => {
    if (!res.getTag) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.getTag
  })
  .catch(res => {
    const parsedErrors = res.errors.map(error => {
      switch (error.errorType) {
        default:
          return makeGenericErrorMessage({context: 'tag', error});
      }
    });
    parsedErrors.unshift(SINGLE_TAG_ERROR);
    throw(parsedErrors);
  });

if (isDev()) {
  window.fetchSingleTag = tagId => {
    fetchSingleTag(tagId).then(console.log, console.log);
  };
}

const CREATE_TAG_MUTATION = `
mutation CreateTag ($name: String!) {
  createTag(input: {
    name: $name
  }) {
    id
    name
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to create a new tag
 * @function
 * @param {string} tagData
 * @param {string} tagData.name
 * @returns {Promise<tag|errors[]>}
 */
export const createTag = tagData =>
  gqlQuery(CREATE_TAG_MUTATION, { name: tagData.name.trim() })
    .then(res => {
      if (!res.createTag) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.createTag;
    })
    .catch(res => {
      const errors = [
        TAG_CREATE_ERROR,
        makeGenericErrorMessage({context: 'tag create', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.createTag = tagData => {
    createTag(tagData).then(console.log, console.log);
  };
}

export const BATCH_CREATE_RECORD_TAG_MUTATION = `
mutation BatchCreateRecordTags ($tags: [CreateRecordTagInput]) {
  batchCreateRecordTag(recordtags: $tags) {
    id
  }
}
`;

export const batchCreateRecordTags = tagArray =>
  gqlQuery(BATCH_CREATE_RECORD_TAG_MUTATION, { tags: tagArray })
    .then(res => {
      if (!res.batchCreateRecordTag) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.batchCreateRecordTag;
    })
    .catch(res => {
      const errors = [
        TAG_CREATE_ERROR,
        makeGenericErrorMessage({context: 'batch record tag create', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.batchCreateRecordTags = tagArray => {
    batchCreateRecordTags(tagArray).then(console.log, console.log);
  };
}

export const BATCH_DELETE_RECORD_TAGS_MUTATION = `
mutation BatchDeleteRecordTags($ids: [ID]) {
  batchDeleteRecordTag(ids: $ids) {
    id
  }
}
`;

export const batchDeleteRecordTags = idArray =>
  gqlQuery(BATCH_DELETE_RECORD_TAGS_MUTATION, { ids: idArray })
    .then(res => {
      if (!res.batchDeleteRecordTag) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.batchDeleteRecordTag;
    })
    .catch(res => {
      const errors = [
        TAG_DELETE_ERROR,
        makeGenericErrorMessage({context: 'batch record tag delete', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.batchDeleteRecordTags = idArray => {
    batchDeleteRecordTags(idArray).then(console.log, console.log);
  };
}

const UPDATE_TAG_MUTATION = `
mutation UpdateTag ($id: ID!, $name: String!){
  updateTag(input: {
    id: $id,
    name: $name
  }) {
    id,
    name
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to update a tag's name
 * @function
 * @param {string} tagData
 * @param {string} tagData.id
 * @param {string} tagData.name
 * @returns {Promise<category|errors[]>}
 */
export const updateTag = tagData =>
  gqlQuery(UPDATE_TAG_MUTATION, {id: tagData.id, name: tagData.name.trim()})
    .then(res => {
      if (!res.updateTag) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.updateTag
    })
    .catch(res => {
      const errors = [
        TAG_UPDATE_ERROR,
        makeGenericErrorMessage({context: 'tag update', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.updateTag = tagData => {
    updateTag(tagData).then(console.log, console.log);
  };
}

const DELETE_TAG_MUTATION = `
mutation DeleteTag($id: ID!) {
  deleteTag(input:{
    id: $id
  }) {
    id
    name
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to delete a tag
 * @function
 * @param {string} tagId
 * @returns {Promise<tag|errors[]>}
 */
export const deleteTag = tagId =>
  gqlQuery(GET_SINGLE_TAG_QUERY, { id: tagId })
    .then(res => {
      if (!res.getTag) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      const tagDeleteData = res.getTag.records.items ? res.getTag.records.items.map(tag => tag.id) : [];
      const tagMutations = [gqlQuery(DELETE_TAG_MUTATION, { id: tagId })];
      if (tagDeleteData.length) {
        tagMutations.push(gqlQuery(BATCH_DELETE_RECORD_TAGS_MUTATION, {ids: tagDeleteData}));
      }
      return Promise.all(tagMutations).then(res => {
        const tag = res[0];
        if (!tag.deleteTag) {
          throw(GQL_NULL_RESPONSE_ERROR);
        }
        return tag.deleteTag;
      })
    })
    .catch(res => {
      const errors = [
        TAG_DELETE_ERROR,
        makeGenericErrorMessage({context: 'tag delete', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.deleteTag = tagId => {
    deleteTag(tagId).then(console.log, console.log);
  };
}
