import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { PageContainer, PageContent } from "../../components";
import { Table, Spinner } from "../../components/widgets";
import { connect } from "react-redux";
import "./style.scss";

import {
  getParsedSearchResults
} from '../../selectors';

import { isSystemAdmin, makeIssueNameWithStatus } from '../../utils';

import {
  CATEGORY_ROUTE_PREFIX,
  ISSUE_ROUTE_PREFIX
} from "../../constants/routes";

import {
  TYPE_CATEGORY,
} from '../../constants';

const mapStateToProps = (state, props) => ({
  isSearching: state.search.isSearching,
  searchString: state.search.searchString,
  data: getParsedSearchResults(state),
  currentUser: state.session.currentUser
});

class Search extends Component {
  get searchResultColumns() {
    return [
      {
        name: 'type',
        displayTransformer: result => (result.type === TYPE_CATEGORY) ? 'Category:' : 'Issue:',
        width: 80
      },
      {
        name: 'title',
        displayTransformer: result => {
          const isCategory = (result.type === TYPE_CATEGORY)
          const prefix = isCategory ? CATEGORY_ROUTE_PREFIX : ISSUE_ROUTE_PREFIX;
          const title = isCategory ? result.title : makeIssueNameWithStatus(result);
          const route = prefix + result.id;
          const tag = isSystemAdmin(this.props.currentUser) && result.tag && result.tag.name;
          return  <Link to={route}>
            {title}
            {tag && <span className="search-result-issue-tag">{tag}</span>}
            </Link>;
        }
      }
    ];
  }

  render() {
    const { isSearching, currentUser, searchString } = this.props;
    const data = isSystemAdmin(currentUser) ?
      this.props.data :
      this.props.data.filter(item => {
        if (item.type === TYPE_CATEGORY) {
          return true;
        }

        return item.published;
      });

    return (
      <PageContainer className="search-results">
        <PageContent>
          {
            isSearching ? <Spinner /> :
              <Fragment>
                <h2 className="search-results-header">Showing results for <span className="search-term">{searchString}</span></h2>
                <Table
                  className="search-table"
                  caption="Search results"
                  columns={this.searchResultColumns}
                  data={data}
                  itemsPerPage={25}
                />
              </Fragment>
          }

        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps
)(Search);
