/**
 * @module utils/categories
 * @desc Helper methods and validators for category forms
 */
import {
  MISSING_CATEGORY_NAME_ERROR,
  INCORRECT_CATEGORY_CONFIRMATION_ERROR,
  DUPLICATE_CATEGORY_NAME_ERROR,
  makeGenericErrorMessage,

} from '../constants/errors';

import { isEmptyString } from './';

/**
 * Checks update category form for errors
 * @param {object} formData
 * @param {string} formData.id
 * @param {string} formData.name
 * @param {object[]} categoryList
 * @returns {node[]} errors
 */
export function validateUpdateCategoryFormData(formData, categoryList) {
  const errors = [];
  const { id, name } = formData;

  if (isEmptyString(name)) {
    errors.push(MISSING_CATEGORY_NAME_ERROR);
  } else if (categoryList.find(category => category.name.trim() === name.trim() )) {
    errors.push(DUPLICATE_CATEGORY_NAME_ERROR);
  }

  if (isEmptyString(id)) {
    errors.push(makeGenericErrorMessage({context: 'update category', error: 'missing id'}));
  }

  return errors;
}

/**
 * Checks create category form for errors
 * @param {object} formData
 * @param {string} formData.name
 * @param {string} formData.confirmation email from form for confirmation
 * @param {string} currentUserEmail for comparison
 * @param {object[]} categoryList
 * @returns {string[]} errors
 */
export function validateCreateCategoryFormData(formData, currentUserEmail, categoryList) {
  const errors = [];
  const { name, confirmation } = formData;

  if (isEmptyString(name)) {
    errors.push(MISSING_CATEGORY_NAME_ERROR);
  } else if (categoryList.find(category => category.name.trim() === name.trim() )) {
    errors.push(DUPLICATE_CATEGORY_NAME_ERROR);
  }

  if (confirmation !== currentUserEmail) {
    errors.push(INCORRECT_CATEGORY_CONFIRMATION_ERROR);
  }

  return errors;
}
