import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../components/widgets";
import { connect } from "react-redux";
import { getSortedCategoryListWithIssueCount } from "../../../selectors";
import {
  CATEGORY_EDIT_ROUTE_PREFIX,
  CATEGORY_CREATE_ROUTE,
  CATEGORY_ROUTE_PREFIX
} from "../../../constants/routes";

const CategoryActionButton = props => {
  const { id, name } = props;

  return (
    <Link to={CATEGORY_EDIT_ROUTE_PREFIX + id} className="category-button-link">
      <i
        className="fas fa-edit"
        title={`Edit ${name}`}
      />
    </Link>
  );
};

const CATEGORY_LIST_COLUMNS = [
  {
    name: "name",
    displayName: "Category Name",
    displayTransformer: category => <Link to={CATEGORY_ROUTE_PREFIX + category.id}>{category.name}</Link>,
    sort: (a, b) => (a.name > b.name) ? 1 : -1,
    filter: (category, filterTerm) => category.name.toLowerCase().includes(filterTerm.toLowerCase()),
    defaultSort: true,
  },
  {
    name: "issueCount",
    displayName: "Issues",
    sort: (a, b) => (a.issueCount > b.issueCount) ? 1 : -1,
    descending: true
  },
  {
    name: "action",
    displayName: "Action",
    displayTransformer: CategoryActionButton,
    width: 70
  }
];

const mapStateToProps = (state, props) => ({
  data: getSortedCategoryListWithIssueCount(state)
});

class CategoryAdminList extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="admin-table">
        <Link to={CATEGORY_CREATE_ROUTE} role="button">
          <i
            className="fas fa-plus-square add-item-btn"
            title="Add a new category"
          >
            <span className="btn-text">Add a category</span>
          </i>
        </Link>
        <Table
          caption="Categories tables"
          columns={CATEGORY_LIST_COLUMNS}
          data={data}
          itemsPerPage={15}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(CategoryAdminList);
