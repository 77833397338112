import {
  LOAD_CAMPUS_LIST,
  SET_CAMPUS_LIST_LOADING_STATUS
} from '../constants/action-types';

const initialState = {
  data: [],
  loaded: false,
  isLoading: false
};

export default function campuses(state = initialState, action) {
  switch (action.type) {

    case LOAD_CAMPUS_LIST:
      return {
        ...state,
        data: action.campuses,
        loaded: true,
      };

    case SET_CAMPUS_LIST_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    default:
      return state;
  }
}
