import Amplify, { Auth, Hub } from "aws-amplify";

import { endSession, initializeUserSessionFromSSO } from './actions';
import Store from './store';
import { isDev } from './utils';
import { awsConf } from "./config/awsConfig";

Auth.configure({
  Auth: {
      identityPoolId: awsConf.IdentityPoolId,
      region: awsConf.CognitoRegion,
      userPoolId: awsConf.UserPoolId,
      userPoolWebClientId: awsConf.ClientId,
      mandatorySignIn: false,
      oauth: awsConf.OauthDetails
  }
});


Auth.configure();

if (isDev()) {
  window.Auth = Auth;
}

const SIGN_IN = 'signIn';
const SIGN_OUT = 'signOut';

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case SIGN_IN:
      Store.dispatch(initializeUserSessionFromSSO());
      break;
    case SIGN_OUT:
      Store.dispatch(endSession());
      break;
    default:
      console.log(event);
  }
});

export default Auth;

