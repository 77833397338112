import { gqlQuery } from "./";
import {
  GQL_NULL_RESPONSE_ERROR,
  CAMPUS_GUIDANCE_CREATE_ERROR,
  CAMPUS_GUIDANCE_UPDATE_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import { isDev } from '../utils';

// here we want to get the full issue back when we update guidance
const UPDATE_CAMPUS_GUIDANCE_MUTATION = `
mutation UpdateCampusGuidance ($id: ID!, $status: CampusGuidanceStatus!, $guidance: String!) {
  updateCampusGuidance(input: {
    id: $id,
    guidance: $guidance,
    status: $status
  }) {
    id,
    record {
      id,
      title,
      agreement_language,
      guidance,
      rationale,
      category {
        id,
        name
      },
      campusGuidance {
        items {
          id,
          status,
          guidance,
          campus {
            id,
            short_name,
            long_name
          }
        }
      }
    }
  }
}
`;

export const updateCampusGuidance = (cgData) =>
  gqlQuery(UPDATE_CAMPUS_GUIDANCE_MUTATION, {
    id: cgData.id,
    status: cgData.status,
    guidance: cgData.guidance
  }).then(res => {
    if (!(res.updateCampusGuidance && res.updateCampusGuidance.record)) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.updateCampusGuidance;
  }).catch(res => {
    const parsedErrors = res.errors.map(error => {
      switch (error.errorType) {
        default:
          return makeGenericErrorMessage({context: 'campus guidance update', error});
      }
    });
    parsedErrors.unshift(CAMPUS_GUIDANCE_UPDATE_ERROR);
    throw(parsedErrors);
  });

if (isDev()) {
  window.updateCampusGuidance = data => {
    updateCampusGuidance(data).then(console.log, console.log);
  };
}

const CREATE_CAMPUS_GUIDANCE_MUTATION = `
mutation CreateCampusGuidance ($status: CampusGuidanceStatus!, $guidance: String!, $record_id: ID!, $campus_id: ID!) {
  createCampusGuidance(input: {
    campusGuidanceRecordId: $record_id,
    campusGuidanceCampusId: $campus_id,
    guidance: $guidance,
    status: $status
  }) {
    id,
    record {
      id,
      title,
      agreement_language,
      guidance,
      rationale,
      category {
        id,
        name
      },
      campusGuidance {
        items {
          id,
          guidance,
          status,
          campus {
            id,
            short_name,
            long_name
          }
        }
      }
    }
  }
}
`;

export const createCampusGuidance = (cgData) =>
  gqlQuery(CREATE_CAMPUS_GUIDANCE_MUTATION, {
    campus_id: cgData.campus_id,
    record_id: cgData.record_id,
    status: cgData.status,
    guidance: cgData.guidance
  }).then(res => {
    if (!(res.createCampusGuidance && res.createCampusGuidance.record)) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.createCampusGuidance;
  }).catch(res => {
    const parsedErrors = res.errors.map(error => {
      switch (error.errorType) {
        default:
          return makeGenericErrorMessage({context: 'campus guidance create', error});
      }
    });
    parsedErrors.unshift(CAMPUS_GUIDANCE_CREATE_ERROR);
    throw(parsedErrors);
  });

if (isDev()) {
  window.createCampusGuidance = data => {
    createCampusGuidance(data).then(console.log, console.log);
  };
}
