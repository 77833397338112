import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "../../components/widgets";

import { makeClassName } from "../../utils";

import {
  setNavSectionVisible,
} from "../../actions";

const mapStateToProps = (state, props) => {
  return {
    visibleSection: state.issues.currentIssue.visibleSection
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNavSectionVisible: id => dispatch(setNavSectionVisible(id)),
  };
};

class IssueSectionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.startCollapsed
    };

    this.ref = createRef();
  }

  get isVisible() {
    const el = this.ref.current;
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    const midpoint = document.documentElement.clientHeight / 2;

    return  (rect.top < midpoint) &&
      (midpoint < rect.bottom) &&
      !this.state.collapsed;
  }

  onScroll = e => {
    if (this.isVisible && (this.props.id !== this.props.visibleSection)) {
      this.props.setNavSectionVisible(this.props.id);
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { id, title, showCollapseButton, showEditButton, editAction } = this.props;
    const shouldHighlight = (this.props.id === this.props.currentSection);

    return (
      <section
        ref={this.ref}
        id={id}
        className="issue-section"
        aria-expanded={this.state.collapsed ? "false" : "true"}
      >
        <div className="header-container">
          <h2 className={makeClassName("issue-section-title", shouldHighlight ? "nav-active-section": "")}>{title}</h2>
          { showCollapseButton && (
            <Button onClick={this.toggleCollapse} className="collapse-btn">
              {this.state.collapsed ? "Expand" : "Collapse"}
            </Button>
          )}
          {!this.state.collapsed && showEditButton &&
              <Button onClick={editAction} className="btn edit-section-button">Edit</Button>
          }
        </div>
        <div className={makeClassName(this.state.collapsed ? "collapsed-section" : "")}>
          {this.props.children}
      </div>
    </section>
    );
  }
}

IssueSectionContainer.propTypes = {
  startCollapsed: PropTypes.bool,
  showCollapseButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  title: PropTypes.string,
  onSave: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueSectionContainer);

