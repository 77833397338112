import { gqlQuery } from './';
import { isDev } from '../utils';
import {
  TYPE_ISSUE,
  TYPE_CATEGORY,
  TYPE_CAMPUS_GUIDANCE,
  TYPE_TAG
} from '../constants';

import {
  SEARCH_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

const SEARCH_RECORD_QUERY = `
query SearchRecord ($term: String!) {
  searchRecords( filter : {
    or: [
        { title: { wildcard: $term } },
        { guidance: { wildcard: $term } },
        { rationale: { wildcard: $term } },
        { agreement_language: { wildcard: $term } },
    ]
  }, limit: 10000) {
    items {
        id,
        title,
        published,
        tags(limit: 10000) {
          items {
            tag {
              id,
              name
            }
          }
        }
    }
  }
}
`;

const SEARCH_CAMPUS_GUIDANCE_QUERY = `
query SearchCampusGuidance ($term: String!) {
  searchCampusGuidances( filter: {
       guidance: { wildcard: $term }
  }, limit: 10000) {
    items {
      record {
        id,
        title,
        published
      }
    }
  }
}
`;

const SEARCH_CATEGORY_QUERY = `
query SearchCategory ($term: String!) {
  searchCategorys ( filter : {
    name : { wildcard : $term }
  }, limit: 10000) {
    items {
        id,
        name
    }
  }
}

`;

const SEARCH_TAG_QUERY = `
query SearchTags ($term: String!) {
  searchTags(filter: {name: {wildcard: $term}}, limit: 10000) {
    items {
      id
      name
      records(limit: 10000) {
        items {
          record {
            id
            title
            published
            tags(limit: 10000) {
              items {
                tag {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
const SEARCH_QUERIES = {
  [TYPE_ISSUE]: SEARCH_RECORD_QUERY,
  [TYPE_CAMPUS_GUIDANCE]: SEARCH_CAMPUS_GUIDANCE_QUERY,
  [TYPE_CATEGORY]: SEARCH_CATEGORY_QUERY,
  [TYPE_TAG]: SEARCH_TAG_QUERY
};

/**
 * Makes several authed api calls to the graphql endpoint to search for a given string
 * @function
 * @returns {Promise<results|errors[]>}
 */
export const searchRecords = (term, searchTypes = []) => {
  const types = searchTypes.filter(type => {
    const isValidType = Boolean(SEARCH_QUERIES[type]);

    if (isDev() && !isValidType) {
      console.error(`${type} is not a valid search type`);
    }

    return isValidType;
  });
  const promises = types
    .map(type => SEARCH_QUERIES[type])
    .map(query => gqlQuery(query, { term: `*${term.toLowerCase()}*` }))

  return Promise.all(promises).then(res => {
    // we want to init our results object with empty arrays for all types since we aren't
    // necessarily always going to search for all types, but we do need to have them all
    // in the returned object
    const results = {
      [TYPE_ISSUE]: [],
      [TYPE_CAMPUS_GUIDANCE]: [],
      [TYPE_CATEGORY]: [],
      [TYPE_TAG]: []
    };

    types.forEach((type, idx) => {
      switch (type) {
        case TYPE_ISSUE:
          results[TYPE_ISSUE] = res[idx].searchRecords.items;
          break;
        case TYPE_CAMPUS_GUIDANCE:
          results[TYPE_CAMPUS_GUIDANCE] = res[idx].searchCampusGuidances.items
            .map(guidance => guidance.record);
          break;
        case TYPE_CATEGORY:
          results[TYPE_CATEGORY] = res[idx].searchCategorys.items
            .map(cat => ({id: cat.id, title: cat.name}));
          break;
        case TYPE_TAG:
          results[TYPE_TAG] = res[idx].searchTags.items
            .reduce((issues, tag) => issues.concat(tag.records.items.map(r => r.record)), []);
          break;
        default:
          break;
      }
    });

    return results;
  }).catch(res => {
    const errors = [
      SEARCH_ERROR,
      makeGenericErrorMessage({context: 'search', error: res.errors})
    ];
    throw(errors);
  });
}

if (isDev()) {
  window.searchRecords = (data, type) => {
    searchRecords(data, type).then(console.log, console.log);
  };
}
