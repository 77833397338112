import React, { Component, Fragment, createRef } from "react";
import "./style.scss";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList,
  Select
} from "../../../components/widgets";

import { USERS_ROUTE } from "../../../constants/routes";

import { ROLES, ROLE_VIEWER } from "../../../constants";

import { getCampusListSelectData, getUserById } from "../../../selectors";

import {
  resetAllMessages,
  resetErrors,
  attemptToUpdateUser
} from "../../../actions";

import { connect } from "react-redux";

import history from "../../../history";

import { isSystemAdmin } from "../../../utils";
import { getCurrentUser } from "../../../selectors";

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;

  return {
    user: getUserById(id)(state),
    shouldLockForm: state.users.actions.isLoading,
    shouldLockSubmit: state.users.actions.isLoading || state.campuses.isLoading,
    errors: state.messages.errors,
    campusList: getCampusListSelectData(state),
    currentUser: getCurrentUser(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.match.params;
  return {
    onSubmit: userData => dispatch(attemptToUpdateUser({ ...userData, id })),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(USERS_ROUTE);
      dispatch(resetErrors());
    },
  };
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.focusName = createRef();
    this.focusRole = createRef();

  }

  componentDidMount() {
    this.props.resetMessages();

    // TODO this doesn't work if we don't have the users list loaded yet
    this.focusName.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    // this will close the modal in the case when someone goes directly to the edit route for a non-confirmed user
    //if (this.props.user.email && !this.props.user.confirmed) {
      //this.props.hideModal();
    //}
  }

  render() {
    const {
      campusList,
      hideModal,
      onSubmit,
      shouldLockForm,
      shouldLockSubmit,
      errors,
      user,
      currentUser
    } = this.props;

    const confirmMessage = user.confirmed ? 'Update' : 'Register';

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"user-action-modal"}>
          <h2>Edit {user.email}</h2>
          <ErrorList errors={errors} />
          <Fragment>
            <TextInput
              focusRef={this.focusName}
              initialValue={user.firstName}
              name="firstname"
              label="First Name*"
              type="text"
              required={true}
              disabled={shouldLockForm}
            />
            <TextInput
              initialValue={user.lastName}
              name="lastname"
              label="Last Name*"
              type="text"
              required={true}
              disabled={shouldLockForm}
            />
            {
              isSystemAdmin(currentUser) ?
                <Fragment>
                  <Select
                    focusRef={this.focusRole}
                    initialValue={user.role}
                    label="Role*"
                    name="role"
                    prompt="Please select a role"
                    options={ROLES}
                    required={true}
                    disabled={shouldLockForm}
                  />
                  <Select
                    initialValue={user.campusId}
                    label="Campus*"
                    name="campusid"
                    prompt="Please select a campus"
                    options={campusList}
                    required={true}
                    disabled={shouldLockForm}
                  />
                </Fragment>
                :
                <Fragment>
                  <input
                    type="hidden"
                    name="role"
                    value={ROLE_VIEWER}
                  />
                  <input
                    type="hidden"
                    name="campusid"
                    value={currentUser.campusId}
                  />
                </Fragment>
            }
          </Fragment>
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockSubmit}
            >
              {shouldLockForm ? <Spinner light /> : confirmMessage}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
