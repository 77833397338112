/**
 * @module constants/fragments
 * @desc exported cost data pertaining to longer bits of text and jsx fragments that we don't want to include inline in a component
 */

import React from 'react';

export * from './user-fragments';
export * from './category-fragments';
export * from './tag-fragments';

export const WELCOME_BLURB = (
  <div className="welcome-text">
    <p>The UCOP Negotiator's Handbook is a collection of information useful to campus administrators negotiating sponsored research agreements. It is organized by common topics, which are labeled <strong>categories</strong>.  Some categories are subdivided into <strong>issues</strong> as needed. All entries include UCOP guidance on UC's standard position, the rationale for that position, sample agreement language, and additional resources. Content is also searchable using smart <strong>tags</strong> which automatically link to related issues.</p>
    <p>Content in the "Rationale" section and acceptable terms from the "Agreement Language" section may be cited verbatim in negotiations. However, the remainder of the content should not be shared with anyone who does not have authorized access.</p>
    <p>Campuses will be able to add campus-specific content for each entry. Campus-specific content is only visible to users from that campus unless the campus chooses to share it with other campuses.</p>
    <p> <strong>Contacts:</strong></p>
    <ul>
      <li>For questions related to <strong>content</strong>, please contact Melissa Waver (<a href="mailto:Melissa.waver@ucop.edu">Melissa.waver@ucop.edu</a>).</li>
      <li>For questions regarding <strong>your user access/permissions</strong>, please contact your campus's <a href="https://www.ucop.edu/research-policy-analysis-coordination/resources-tools/negotiators-handbook/campus-location-access-coordinators.html">Location Access Coordinator</a>.</li>
      <li>For <strong>technical or other questions</strong>, please contact <a href="mailto:NHB@ucop.edu">NHB@ucop.edu</a>.</li>
    </ul>
  </div>
);

export const WELCOME_BLURB_UNAUTHED = (
  <div className="welcome-text">
    <p>Your Negotiator's Handbook user account is currently <strong>pending</strong>. Please contact your campus's <a href="https://www.ucop.edu/research-policy-analysis-coordination/resources-tools/negotiators-handbook/campus-location-access-coordinators.html">Location Access Coordinator for access</a>.</p>
    <br/>
    <p>For technical or other questions, please contact <a href="mailto:NHB@ucop.edu.">NHB@ucop.edu</a>.</p>
  </div>
);
