import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.page || 1
    };
  }

  increment = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.state.currentPage + 1, this.props.totalPages);
    }
  };

  decrement = () => {
    this.setState({
      currentPage: this.state.currentPage - 1
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.state.currentPage - 1, this.props.totalPages);
    }
  };

  componentDidUpdate(prevProps) {
    // if we get a new page number from the table then we just go there right away
    if (
      (this.props.page !== this.state.currentPage) &&
      (this.props.page !== prevProps.page)
    ) {
      this.setState({
        currentPage: this.props.page
      })
    }
  }

  render() {
    const { focusRef } = this.props;
    const onFirstPage = this.state.currentPage === 1;
    const onLastPage = this.state.currentPage === this.props.totalPages;
    const currentpage = this.state.currentPage;

    return (
      <div className="pagination">
        {!onFirstPage && (
          <Fragment>
            <button onClick={this.decrement} aria-label="previous page">
              &laquo;
            </button>
            <button
              onClick={this.decrement}
              aria-label={`Page ${currentpage - 1}`}
            >
              {currentpage - 1}
            </button>
          </Fragment>
        )}
        <button ref={focusRef} className="active" aria-label={`Active page ${currentpage}`}>
          {currentpage}
        </button>
        {!onLastPage && (
          <Fragment>
            <button
              onClick={this.increment}
              aria-label={`Page ${currentpage + 1}`}
            >
              {currentpage + 1}
            </button>
            <button onClick={this.increment} aria-label="next page">
              &raquo;
            </button>
          </Fragment>
        )}
      </div>
    );
  }
}

Pagination.propTypes = {
  onChange: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  focusRef: PropTypes.object
};

export default Pagination;
