import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { makeClassName } from "../../../utils";

class ErrorList extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
    this.focusOnError = this.focusOnError.bind(this);
  }

  focusOnError() {
    if (this.props.shouldFocusOnError && this.props.errors.length) {
      this.focusRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.focusOnError();
    }
  }

  render() {
    const { title, errors, id, className } = this.props;
    return (
      <div
        id={id}
        className={makeClassName("ucop-error-list", className)}
        ref={this.focusRef}
        tabIndex={errors.length > 0 ? "-1" : ""}
        role="alert"
      >
        {title && errors.length > 0 ? <h4>{title}</h4> : null}
        <ul>
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }
}

ErrorList.defaultProps = {
  shouldFocusOnError: true,
  errors: []
};

ErrorList.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.node).isRequired,
  shouldFocusOnError: PropTypes.bool
};

export default ErrorList;
