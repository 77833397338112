import { createSelector } from "reselect";
import {
  TYPE_ISSUE,
  TYPE_CATEGORY,
  TYPE_CAMPUS_GUIDANCE,
  TYPE_TAG
} from '../constants';

const getSearchResults = state => state.search.data;

export const getParsedSearchResults = createSelector(
  [getSearchResults],
  searchResults => {
    const seenIssues = {};
    const combinedIssues = [];
    searchResults[TYPE_ISSUE]
      .concat(searchResults[TYPE_CAMPUS_GUIDANCE])
      .concat(searchResults[TYPE_TAG])
      .forEach(item => {
      if (!seenIssues[item.id]) {
        seenIssues[item.id] = true;
        item.type = TYPE_ISSUE;
        combinedIssues.push(item);
      }
    });
    const categories = searchResults[TYPE_CATEGORY].map(item => ({...item, type: TYPE_CATEGORY}));

    return categories.concat(combinedIssues);
  }
);

