import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { USERS_DELETE_ROUTE_PREFIX, USERS_EDIT_ROUTE_PREFIX } from "../../../constants/routes";

const UserButtons = props => {
  const { email, id } = props;
  return (
    <Fragment>
      <Link to={USERS_DELETE_ROUTE_PREFIX + id} className="user-button-link">
        <i
          className="fas fa-user-times"
          title={`Delete ${email}`}
        />
      </Link>
      <Link to={USERS_EDIT_ROUTE_PREFIX + id} className="user-button-link">
        <i
          className="fas fa-user-edit"
          title={`Edit ${email}`}
        />
      </Link>
    </Fragment>
  );
};

export default UserButtons;
