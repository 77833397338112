import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Spinner } from '../widgets';
import { executeSearch } from '../../actions';
import { isSystemAdmin } from '../../utils';
import {
  TYPE_ISSUE,
  TYPE_CAMPUS_GUIDANCE,
  TYPE_CATEGORY,
  TYPE_TAG
} from '../../constants';

import "./style.scss";

const mapStateToProps = (state, props) => ({
  searchString: state.search.searchString,
  isSearching: state.search.isSearching,
  currentUser: state.session.currentUser
});

const mapDispatchToProps = (dispatch, props) => ({
  search: (searchString, searchTypes) => dispatch(executeSearch(searchString, searchTypes))
});

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  get isEmpty() {
    return !this.state.value;
  }

  get shouldLockSubmit() {
    return this.isEmpty || this.props.isSearching;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString) {
      this.setState({
        value: this.props.searchString
      });
    }
  }

  onChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  onSubmit(event) {
    event.preventDefault();

    const searchTypes = isSystemAdmin(this.props.currentUser) ?
      [TYPE_ISSUE, TYPE_CAMPUS_GUIDANCE, TYPE_CATEGORY, TYPE_TAG] :
      [TYPE_ISSUE, TYPE_CAMPUS_GUIDANCE, TYPE_CATEGORY];

    this.props.search(this.state.value, searchTypes);
  }

  render() {
    const { isSearching } = this.props;

    return (
      <form className="search-bar" onSubmit={this.onSubmit}>
        <input
          type="text"
          value={this.state.value}
          placeholder="Search"
          onChange={this.onChange}
          disabled={isSearching}
        />
        <button type="submit" disabled={this.shouldLockSubmit}>
          {
            isSearching ?
            <Spinner light small /> :
            <Fragment>
              <i className="fas fa-search" />
              <span className="sr_hide">Search</span>
            </Fragment>
          }
        </button>
      </form>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
