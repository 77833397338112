import React from "react";
import "./style.scss";

const Footer = props => {
  const year = new Date().getFullYear();
  return (
    <footer className="app-footer">
      &copy; University of California, {year}
    </footer>
  );
};

export default Footer;
