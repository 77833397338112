import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PageContainer, PageContent } from "../../components";
import { Spinner } from "../../components/widgets";
import "./style.scss";

import {
  getViewableCampusGuidanceForCurrentIssue,
  getFormattedPreviousVersionList,
  getFormattedPreviousVersion
} from "../../selectors";

import {
  fetchAndLoadSingleIssue,
  resetAllMessages,
  fetchAndLoadCampusList,
  updateCurrentIssue,
  createOrUpdateCampusGuidanceForCurrentIssue,
} from "../../actions";

import {
  isSystemAdmin,
  isCampusAdmin
} from "../../utils";

import IssueSection from "./issue-section";
import IssueNav from "./issue-nav";
import IssueTitle from "./issue-title";
import Breadcrumbs from "./breadcrumbs";
import CampusGuidance from "./campus-guidance";
import PreviousVersionList from "./previous-version-list";

const mapStateToProps = (state, props) => {
  const versionId = props.match.params.versionId;

  return {
    currentUser: state.session.currentUser,
    issue: state.issues.currentIssue.data,
    isLoading: state.issues.currentIssue.isLoading || state.campuses.isLoading,
    campusListLoaded: state.campuses.loaded,
    isUpdating: state.issues.currentIssue.isUpdating,
    isUnpublished: state.issues.currentIssue.data && !state.issues.currentIssue.data.published,
    campusGuidanceList: getViewableCampusGuidanceForCurrentIssue(state),
    visibleSection: state.issues.currentIssue.visibleSection,
    viewingPreviousVersion: Boolean(versionId),
    // show version info if any of the sections are being edited (val === true)
    showVersionInfo: Boolean(versionId) || Object.values(state.issues.currentIssue.sectionEditStatus).some(val => val),
    previousVersionList: getFormattedPreviousVersionList(state),
    previousVersion: getFormattedPreviousVersion(state),
    versionId
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const id = props.match.params.id;

  return {
    fetchAndLoadIssue: (versionId) => dispatch(fetchAndLoadSingleIssue(id, versionId)),
    resetAllMessages: () => dispatch(resetAllMessages()),
    fetchAndLoadCampusList: () => dispatch(fetchAndLoadCampusList()),
    onSaveIssue: (content, skipRawTextCheck) => dispatch(updateCurrentIssue(content, skipRawTextCheck)),
    onSaveCampusGuidance: data => dispatch(createOrUpdateCampusGuidanceForCurrentIssue({
      ...data,
      record_id: id
    })),
  };
};

class IssuePage extends Component {
  componentDidMount() {
    this.props.fetchAndLoadCampusList();
    this.props.resetAllMessages();
    this.props.fetchAndLoadIssue(this.isAdmin ? this.props.versionId : null);
  }

  componentDidUpdate(prevProps) {
    if (this.props.versionId !== prevProps.versionId) {
      this.props.fetchAndLoadIssue(this.isAdmin ? this.props.versionId : null);
    }
  }

  get isAdmin() {
    return isSystemAdmin(this.props.currentUser);
  }

  get isCampusAdmin() {
    return isCampusAdmin(this.props.currentUser);
  }

  savePreviousVersionAsCurrent() {
    const { previousVersion, onSaveIssue } = this.props;

    if (previousVersion) {
      onSaveIssue({
        title: previousVersion.title,
        guidance: previousVersion.guidance,
        rationale: previousVersion.rationale,
        agreement_language: previousVersion.agreement_language
      }, true); // true to skip raw text check, we only need that when using an editor
    }
  }

  render() {
    const {
      campusGuidanceList,
      campusListLoaded,
      isUnpublished,
      isLoading,
      isUpdating,
      issue,
      onSaveIssue,
      onSaveCampusGuidance,
      visibleSection,
      showVersionInfo,
      previousVersionList,
      previousVersion,
      viewingPreviousVersion,
      versionId
    } = this.props;

    const currentVersion = previousVersionList[0];

    return (
      <Fragment>
        { issue &&
            <Fragment>
              <Breadcrumbs
                issue={issue}
                previousVersion={previousVersion}
                activateVersionFunction={() => this.savePreviousVersionAsCurrent()}
              />
              <IssueTitle
                isUnpublished={isUnpublished}
                canEdit={!viewingPreviousVersion && this.isAdmin}
                issue={issue}
                onSave={onSaveIssue}
                currentVersion={currentVersion}
                previousVersion={previousVersion}
              />
            </Fragment>
        }
        <PageContainer className="issue-view">
          { issue &&
              <IssueNav
                visibleSection={visibleSection}
                issue={issue}
                campusGuidanceList={campusGuidanceList}
                showVersionMenuItem={this.isAdmin && showVersionInfo}
              />
          }
          <PageContent>
            { isLoading && <Spinner /> }
            {
              issue &&
                <Fragment>
                  <IssueSection
                    id="ucop-guidance"
                    title="UCOP Guidance"
                    canEdit={!viewingPreviousVersion && this.isAdmin}
                    content={previousVersion ? previousVersion.guidance : issue.guidance}
                    field="guidance"
                    onSave={onSaveIssue}
                    shouldLockForm={isUpdating}
                  />
                  <IssueSection
                    id="rationale"
                    title="Rationale"
                    canEdit={!viewingPreviousVersion && this.isAdmin}
                    content={previousVersion ? previousVersion.rationale : issue.rationale}
                    field="rationale"
                    onSave={onSaveIssue}
                    shouldLockForm={isUpdating}
                  />
                  <IssueSection
                    id="agreement-language"
                    title="Agreement Language"
                    canEdit={!viewingPreviousVersion && this.isAdmin}
                    content={previousVersion ? previousVersion.agreement_language : issue.agreement_language}
                    field="agreement_language"
                    onSave={onSaveIssue}
                    shouldLockForm={isUpdating}
                  />
                  { campusListLoaded &&
                      <div className="campus-guidance">
                        {
                          campusGuidanceList.map(guidanceData => {
                            const {campus, campusGuidance, canEdit} = guidanceData;

                            return <CampusGuidance
                              key={campus.id}
                              campus={campus}
                              canEdit={canEdit}
                              campusGuidance={campusGuidance}
                              shouldLockForm={isUpdating}
                              onSave={onSaveCampusGuidance}
                              shouldShowStatus={this.isAdmin || this.isCampusAdmin}
                            />
                          }) }
                        </div>
                  }
                  {
                    showVersionInfo &&
                      <PreviousVersionList
                        id="previous-version-list"
                        previousVersions={previousVersionList}
                        currentIssueId={issue && issue.id}
                        versionId={versionId}
                      />
                  }
                </Fragment>
            }
          </PageContent>
        </PageContainer>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuePage);
