/**
 * @module actions/category-actions
 * @desc Redux actions to handle category related things
 */
import {
  LOAD_CATEGORY_LIST,
  SET_CATEGORY_LIST_LOADING_STATUS,
  LOAD_CATEGORY_ISSUE_LIST,
  SET_CATEGORY_ISSUE_LIST_LOADING_STATUS,
  SET_CATEGORY_ACTION_LOADING_STATUS
} from '../constants/action-types';

import {
  makeUpdateCategorySuccessMessage,
  makeCreateCategorySuccessMessage
} from '../constants/fragments';

import {
  makeGenericErrorMessage
} from '../constants/errors';

import {
  setErrors,
  setSuccessMessage,
  resetAllMessages
} from './';

import {
  fetchCategoryList,
  fetchIssueListForCategory,
  updateCategory,
  createCategory
} from '../api';

import {
  validateUpdateCategoryFormData,
  validateCreateCategoryFormData
} from '../utils';

import {
  getCategoryById
} from '../selectors';

import { CATEGORY_LIST_ROUTE } from '../constants/routes';
import history from '../history';

const loadCategoryList = data => ({
  type: LOAD_CATEGORY_LIST,
  data
});

const setCategoryListLoadingStatus = isLoading => ({
  type: SET_CATEGORY_LIST_LOADING_STATUS,
  isLoading
});

const loadCategoryIssueList = data => ({
  type: LOAD_CATEGORY_ISSUE_LIST,
  data
});

const clearCategoryIssueList = () => ({
  type: LOAD_CATEGORY_ISSUE_LIST,
  data: []
});

const setCategoryIssueListLoadingStatus = isLoading => ({
  type: SET_CATEGORY_ISSUE_LIST_LOADING_STATUS,
  isLoading
});

const setCategoryActionLoadingStatus = isLoading => ({
  type: SET_CATEGORY_ACTION_LOADING_STATUS,
  isLoading
});

/**
 * Makes an api call to retrieve the category list if it isn't in the store already.
 * If successful it triggers an action to add it to the store.
 * @function
 * @param {boolean} force If this is false then the function returns early if it has been called before
 */
export const fetchAndLoadCategoryList = (force = false) => (dispatch, getState) => {
  const list = getState().categories.list;

  // we only need to do this once until we logout
  if (list.loaded && !force) {
    return false;
  }

  dispatch(setCategoryListLoadingStatus(true));
  fetchCategoryList().then(list => {
    dispatch(loadCategoryList(list));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setCategoryListLoadingStatus(false));
  });
};

/**
 * Makes an api call to retrieve the list of issues for a given category.
 * If successfull it loads them in the store. Otherwise it sets an error message.
 * @function
 * @param {string} categoryId
 */
export const fetchAndLoadCategoryIssueList = categoryId => (dispatch, getState) => {
  dispatch(clearCategoryIssueList());
  dispatch(setCategoryIssueListLoadingStatus(true));
  fetchIssueListForCategory(categoryId).then(list => {
    dispatch(loadCategoryIssueList(list));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setCategoryIssueListLoadingStatus(false));
  });
};

/**
 * Makes an api call to change a category's name
 * If successfull it reloads the category list. Otherwise it sets an error message.
 * @function
 * @param {object} formData
 * @param {string} formData.name
 * @param {string} formData.id
 *
 */
export const attemptToUpdateCategory = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const categoryList = getState().categories.list.data;

  if (categoryList.length === 0) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'update category', error: 'category list not loaded'})
    ));
    return;
  }

  const { id } = formData;
  const category = getCategoryById(id)(getState());
  if (!category) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'update category', error: 'unable to find category by id'})
    ));
    return;
  }

  // if the user didn't actually change the name then we can just exit
  if (category.name.trim() === formData.name.trim()) {
    history.replace(CATEGORY_LIST_ROUTE);
    return;
  }

  const errors = validateUpdateCategoryFormData(formData, categoryList);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setCategoryActionLoadingStatus(true));
  updateCategory(formData).then(res => {
    history.replace(CATEGORY_LIST_ROUTE);
    dispatch(setSuccessMessage(makeUpdateCategorySuccessMessage(res.name)));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadCategoryList(true));
    dispatch(setCategoryActionLoadingStatus(false));
  })
};

/**
 * Makes an api call to create a new category
 * @function
 * @param {object} formData
 * @param {string} formData.name
 * @param {string} formData.confirmation current user's email as confirmation
 */
export const attemptToCreateCategory = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const categoryList = getState().categories.list.data;
  if (categoryList.length === 0) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'create category', error: 'category list not loaded'})
    ));
    return;
  }

  const currentUser = getState().session.currentUser;
  if (!(currentUser && currentUser.email)) {
    dispatch(setErrors(makeGenericErrorMessage({context: 'create category', error: 'no current user'})));
    return;
  }

  const errors = validateCreateCategoryFormData(formData, currentUser.email, categoryList);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setCategoryActionLoadingStatus(true));
  createCategory(formData).then(res => {
    history.replace(CATEGORY_LIST_ROUTE);
    dispatch(setSuccessMessage(makeCreateCategorySuccessMessage(res.name)));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadCategoryList(true));
    dispatch(setCategoryActionLoadingStatus(false));
  })
};
