export const CATEGORY_LIST_ERROR =
  "We are unable to load the category list at this time";
export const CATEGORY_VIEW_ERROR =
  "We are unable to load this category at this time";
export const CATEGORY_UPDATE_ERROR =
  "We are unable to update the category at this time";
export const CATEGORY_CREATE_ERROR =
  "We are unable to create the category at this time";
export const MISSING_CATEGORY_NAME_ERROR = "Please provide a category name";
export const INCORRECT_CATEGORY_CONFIRMATION_ERROR =
  "To verify that you actually want to create a new category, please enter the email address associated with your account.";
export const DUPLICATE_CATEGORY_NAME_ERROR = "Category name already exists. Please enter a different category name";
