/**
 * @module selectors/categories
 * @desc Selectors for category data
 */

import { createSelector } from 'reselect';

const getCategoryList = state => state.categories.list.data;

/**
 * Returns list of categories in the store, sorted alphabetically
 * @function
 * @param {Object} state
 * @returns {Object[]} sorted categories
 */
export const getSortedCategoryList = createSelector(
  [getCategoryList],
  list => list.sort((a, b) => a.name < b.name ? -1 : 1) // sort alphabetically
);

export const getCategorySelectList = createSelector(
  [getSortedCategoryList],
  list => list.map(category => ({name: category.name, value: category.id }))
);

// TODO this is temporary until we get aggregates from gql
export const getSortedCategoryListWithIssueCount = createSelector(
  [getSortedCategoryList],
  list => list.map(category => {
    return {
      id: category.id,
      name: category.name,
      issueCount: String(category.records.items.length)
    };
  })
);

/**
 * Returns a selector function to get a specific category's data
 * @function
 * @param {string} id
 * @returns {function(id)} selector function
 * @example const category = getCategoryById(userId)(state);
 */
export const getCategoryById = id => createSelector(
  [getCategoryList],
  categoryList => {
    const category = categoryList.find(c => c.id === id);
    if (!category) {
      return null;
    }

    return {
      id: category.id,
      name: category.name
    };
  }
);

export const getCategoryByName = name => createSelector(
  [getCategoryList],
  categoryList => {
    const category = categoryList.find(c => c.name === name);
    if (!category) {
      return null;
    }

    return {
      id: category.id,
      name: category.name
    };
  }
);
