import React, { Component } from "react";
import { Spinner, Button, ErrorList } from "../../components/widgets";
import LoginWrapper from './login-wrapper';

import { connect } from 'react-redux';

import Auth from '../../auth';

import {
  resetErrors,
} from '../../actions';


class LoginForm extends Component {
  componentDidMount() {
    this.props.clearLoginErrors();
  }

  render() {
    const { signIn, errors, shouldLockForm} = this.props;

    return (
      <LoginWrapper>
        <ErrorList errors={errors} />
        <Button
          className="login-button"
          name="Sign in using SSO"
          label="Sign in using SSO"
          onClick={signIn}
          disabled={shouldLockForm}
        >{shouldLockForm ? <Spinner light/> : "Sign in with SSO"}</Button>
      </LoginWrapper>
    );
  }
}

export default connect(
  (state, props) => ({
    // 'code' will be in the url if we are redirected from SSO login.
    // We need to check window.location.search because this.props.location and this.props.history don't include 'code'.
    // This is probably because we are using the hash router
    shouldLockForm: window.location.search.search('code') !== -1,
    errors: state.messages.errors,
  }),
  (dispatch, props) => ({
    clearLoginErrors: () => dispatch(resetErrors()),
    signIn: () => Auth.federatedSignIn()
  })
)(LoginForm);
