import React, { Component, createRef } from "react";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Select,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { ISSUE_LIST_ROUTE } from "../../../constants/routes";

import { connect } from "react-redux";
import history from "../../../history";

import {
  resetAllMessages,
  resetErrors,
  fetchAndLoadCategoryList,
  attemptToCreateIssue
} from "../../../actions";

import { getCategorySelectList } from "../../../selectors";

const mapStateToProps = (state, props) => {
  return {
    errors: state.messages.errors,
    shouldLockForm: state.categories.list.isLoading || state.issues.currentIssue.isUpdating,
    categories: getCategorySelectList(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: issueData => dispatch(attemptToCreateIssue(issueData)),
    fetchAndLoadCategoryList: () => dispatch(fetchAndLoadCategoryList()),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(ISSUE_LIST_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class CreateIssue extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }

  componentDidMount() {
    this.props.resetMessages();
    this.props.fetchAndLoadCategoryList();
    this.focusRef.current.focus();
  }

  render() {
    const { categories, hideModal, onSubmit, shouldLockForm, errors } = this.props;

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"ucop-form-editcategory"}>
          <h2>Create a new Issue</h2>
          <br />
          <ErrorList errors={errors} />
          <TextInput
            focusRef={this.focusRef}
            name="title"
            label="Issue Title*"
            type="text"
            required
            disabled={shouldLockForm}
          />
          <Select
            label="Category*"
            name="categoryid"
            options={categories}
            required
            disabled={shouldLockForm}
          />
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner light /> : "Create"}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateIssue);

