/**
 * @module selectors/tags
 * @desc Selectors for tag data
 */

import { createSelector } from 'reselect';

const getTagList = state => state.tags.list.data;

/**
 * Returns list of tags in the store, sorted alphabetically
 * @function
 * @param {Object} state
 * @returns {Object[]} sorted categories
 */
export const getSortedTagList = createSelector(
  [getTagList],
  list => list.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) // sort alphabetically
);

/**
 * Returns an array of tags with their name and id for use in a dropdown select list
 * @function
 * @param {Object} state
 * @returns {Object[]} array of tags with values
 */
export const getTagSelectList = createSelector(
  [getSortedTagList],
  list => {
    const sortedTags = list.map(tag => ({name: tag.name, value: tag.id }));
    return [
      { name: '- No Tag -', value: '' },
      ...sortedTags
    ];
  }
);

/**
 * Returns an array of tags with their name, id, and associated issues
 * @function
 * @param {Object} state
 * @returns {Object[]} array of tags
 */
export const getSortedTagListWithIssueData = createSelector(
  [getSortedTagList],
  list => list.map(tag => ({
    id: tag.id,
    name: tag.name,
    issues: tag.records.items.map(r => r.record).sort ((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1),
    issueCount: String(tag.records.items.length)
  }))
);

// TODO this is temporary until we get aggregates from gql
export const getSortedTagListWithIssueCount = createSelector(
  [getSortedTagList],
  list => list.map(tag => {
    return {
      id: tag.id,
      name: tag.name,
      issueCount: String(tag.records.items.length)
    };
  })
);

/**
 * Returns a selector function to get a specific tag's data
 * @function
 * @param {string} id
 * @returns {function(id)} selector function
 * @example const tag = getTagById(tagId)(state);
 */
export const getTagById = id => createSelector(
  [getTagList],
  tagList => {
    const tag = tagList.find(t => t.id === id);
    if (!tag) {
      return null;
    }

    return {
      id: tag.id,
      name: tag.name
    };
  }
);
