/**
 * @module api/campuses
 * @desc Campus related api functions. Probably just used for getting a list of campuses
 */

import { gqlQuery } from './';
import {
  CAMPUS_LIST_ERROR,
  GQL_NULL_RESPONSE_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import {
  isDev
} from '../utils';

const LIST_CAMPUS_QUERY = `
query ListCampuses {
    listCampuss(limit: 1000) {
    items {
      id,
      short_name,
      long_name,
    }
  }
}
`;

/**
 * Makes an authed api call to the grapql endpoint to retrieve a list of campuses
 * @function
 * @returns {Promise<campuses[]|errors[]>}
 */
export const fetchCampusList = () => gqlQuery(LIST_CAMPUS_QUERY)
  .then(res => {
    if (!res.listCampuss.items) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.listCampuss.items;
  })
  .catch(res => {
    const errors = [
      CAMPUS_LIST_ERROR,
      makeGenericErrorMessage({context: 'campus list', error: res.errors})
    ];
    throw(errors);
  });

if (isDev()) {
  window.fetchCampusList = () => {
    fetchCampusList().then(console.log, console.log);
  };
}
