import { combineReducers } from 'redux';

import {
  LOAD_USER_LIST,
  SET_USER_LIST_LOADING_STATUS,
  SET_USER_ACTION_ERROR,
  SET_USER_ACTION_SUCCESS_MESSAGE,
  SET_USER_ACTION_LOADING_STATUS
} from '../constants/action-types';

const usersInitialState = {
  data: [],
  isLoading: false
};

function list(state = usersInitialState, action) {
  switch (action.type) {
    case LOAD_USER_LIST:
      return {
        ...state,
        data: action.users,
        isLoading: false
      };

    case SET_USER_LIST_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    default:
      return state;
  }
}

const actionsInitialState = {
  errors: [],
  success: null,
  isLoading: false
};

function actions(state = actionsInitialState, action) {
  switch (action.type) {
    case SET_USER_ACTION_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case SET_USER_ACTION_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.message
      };
    case SET_USER_ACTION_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  actions
});
