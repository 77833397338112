import React from "react";
import Auth from '../../auth';
import LoginWrapper from './login-wrapper';
import { Button } from '../../components/widgets';
import { WELCOME_BLURB_UNAUTHED } from "../../constants/fragments";

const Unregistered = props => (
  <LoginWrapper>
    <div className="unregistered-text-container">
      { WELCOME_BLURB_UNAUTHED }
    </div>
    <Button
      className="login-button"
      name="Sign Out"
      label="Sign Out"
      onClick={() => Auth.signOut()}
    >Sign Out</Button>
  </LoginWrapper>
);

export default Unregistered;
