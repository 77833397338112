/**
 * @module actions/campus-actions
 * @desc Redux and thunk actions to handle fetching and loading the campus list
  */
import {
  LOAD_CAMPUS_LIST,
  SET_CAMPUS_LIST_LOADING_STATUS
} from '../constants/action-types';

import {
  fetchCampusList
} from '../api';

import {
  setErrors
} from './';

const loadCampusList = campuses => ({
  type: LOAD_CAMPUS_LIST,
  campuses
});

const setCampusListLoadingStatus = isLoading => ({
  type: SET_CAMPUS_LIST_LOADING_STATUS,
  isLoading
});

/**
 * Action to retrieve the campus list and load it into the store
 * @function
 */
export const fetchAndLoadCampusList = () => (dispatch, getState) => {
  if (getState().campuses.loaded) {
    return false;
  }

  dispatch(setCampusListLoadingStatus(true));
  fetchCampusList().then(campuses => {
    dispatch(loadCampusList(campuses));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setCampusListLoadingStatus(false));
  });
};
