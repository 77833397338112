/**
 * @module constants/errors
 * @desc Primarily jsut exported const data pertaining to error messages.
 */

import React, { Fragment } from 'react';

export * from './login-errors';
export * from './user-errors';
export * from './campus-errors';
export * from './category-errors';
export * from './issue-errors';
export * from './campus-guidance-errors';
export * from './search-errors';
export * from './tag-errors';

export const GQL_NULL_RESPONSE_ERROR = {
  errors: [
    {error: 'null response from gql'}
  ]
}

/**
 * Method to create a generic error message with an email link and some contextual data
 * @function
 * @param {object} error error data as well as any other context. This object is stringified and inserted in the body of the email
 * @returns {node} a jsx node with the message, email link, and contextual data
 */
export const makeGenericErrorMessage = error => {
  // doing it this way to have time and url at the front of the object
  error = {
    time: new Date().toISOString(),
    url: ` ${window.location.href} `,
    ...error
  };
  const email = 'infohelp@ucop.edu';
  const subject = 'NHB Error report'
  const body = `
  ErrorInfo:
  ${JSON.stringify(error)}
  `;

  const emailString = `mailto:${email}?subject=${subject}&body=${body}`;
  return (
      <Fragment>Something went wrong on our end. If the problem persists please try refreshing the page or logging out and back in. If this doesn't work then please contact us at <a href={emailString}>{email}</a>.</Fragment>
  );
};

export const GENERIC_ERROR = 'Unknown error';
