/**
 * @module constants/fragments/user-fragments
 * @desc Text and jsx fragments related to users and user management. Also some fragment generating methods.
 */

/**
 * Generates a success message for use when a user is created.
 * @function
 * @param {object} user
 * @param {string} user.email
 */
export const makeAddUserSuccessMessage = user => `${user.email} has been added successfully`;

/**
 * Generates a success message for use when a user is updated.
 * @function
 * @param {object} user
 * @param {string} user.email
 */
export const makeEditUserSuccessMessage = user => `${user.email} has been updated`;

/**
 * Generates a success message for use when a user is updated.
 * @function
 * @param {object} user
 * @param {string} user.email
 */
 export const makeUpdateNameSuccessMessage = () => `Your first name and last name were updated successfully`;

/**
 * Generates a success message for use when a user is deleted.
 * @function
 * @param {object} user
 * @param {string} user.email
 */
export const makeDeleteUserSuccessMessage = user => `${user.email} has been deleted`;
