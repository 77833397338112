import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './style.scss';

import { Link } from 'react-router-dom';

import {
  CategoryList,
  PageContainer,
  PageContent
} from '../../components';

import {
  Spinner,
  Table
} from '../../components/widgets';

import {
  fetchAndLoadCategoryList,
  fetchAndLoadCategoryIssueList,
  resetAllMessages
} from '../../actions';

import {
  ISSUE_ROUTE_PREFIX
}  from '../../constants/routes';

import { isSystemAdmin, makeIssueNameWithStatus } from '../../utils';

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;
  const category = state.categories.list.data.find(c => c.id === id);

  return {
    category,
    isLoadingIssues: state.categories.issues.isLoading,
    issueList: state.categories.issues.data,
    currentUser: state.session.currentUser
  };
};

const mapDistpatchToProps = (dispatch, props) => {
  const id = props.match.params.id;
  return {
    loadIssueList: () => dispatch(fetchAndLoadCategoryIssueList(id)),
    loadCategoryList: () => dispatch(fetchAndLoadCategoryList()),
    resetAllMessages: () => dispatch(resetAllMessages())
  };
};

const ISSUE_LIST_COLUMNS = [{
  name: 'link',
  displayName: 'Issue Name',
  displayTransformer: issue => <Link to={ISSUE_ROUTE_PREFIX + issue.id}>{makeIssueNameWithStatus(issue)}</Link>,
  sort: (a, b) => {
    if (a.published && !b.published) {
      return -1;
    }

    if (!a.published && b.published) {
      return 1;
    }

    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
  },
  defaultSort: true
}];

class CategoryView extends Component {
  componentDidMount() {
    this.props.resetAllMessages();
    this.props.loadCategoryList();
    this.props.loadIssueList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.props.loadIssueList();
    }
  }

  render() {
    const { currentUser, category, isLoadingIssues } = this.props;
    const issueList = isSystemAdmin(currentUser) ?
      this.props.issueList :
      this.props.issueList.filter(issue => issue.published);

    return (
      <PageContainer className="category-issue-list">
        <CategoryList categoryId={category ? category.id : null}/>
        <PageContent>
          { category &&
              <Fragment>
                <h1>{category.name}</h1>
                <br/>
                <Table
                  caption={category.name}
                  itemsPerPage={10}
                  columns={ISSUE_LIST_COLUMNS}
                  data={issueList}
                />
              </Fragment>
          }
          { isLoadingIssues &&
              <Fragment>
                <br />
                <Spinner />
              </Fragment>
          }
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDistpatchToProps
)(CategoryView);
