// TODO do we want any sort of auto-focus?

import React, { Component, createRef } from "react";
import { Modal } from "../../../components";
import {
  Form,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { getTagById } from "../../../selectors";

import { TAG_LIST_ROUTE } from "../../../constants/routes";

import {
  resetAllMessages,
  resetErrors,
  attemptToDeleteTag
} from "../../../actions";

import { connect } from "react-redux";
import history from "../../../history";

//import { attemptToCreateTag } from "../../../actions";

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;

  return {
    tag: getTagById(id)(state),
    shouldLockForm: state.tags.list.isLoading || state.tags.actions.isLoading,
    shouldLockSubmit: state.tags.list.isLoading,
    errors: state.messages.errors
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.match.params;

  return {
    onSubmit: () => dispatch(attemptToDeleteTag(id)),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(TAG_LIST_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class DeleteTag extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }

  componentDidMount() {
    this.props.resetMessages();
    this.focusRef.current.focus();
  }

  render() {
    const {
      hideModal,
      onSubmit,
      shouldLockForm,
      shouldLockSubmit,
      errors,
      tag
    } = this.props;

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"ucop-form-deletetag"}>
          <h2>Delete {tag ? tag.name : "Tag"}</h2>
          <br />
          <ErrorList errors={errors} />
          <p>Are you sure you want to delete <em>{tag ? tag.name : "Tag"}?</em></p>
          <p>Once you press confirm, issues originally tagged will no longer be associated.</p>
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockSubmit}
            >
              {shouldLockForm ? <Spinner light /> : "Confirm"}
            </Button>
            <Button
              className="cancel-button"
              focusRef={this.focusRef}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTag);
