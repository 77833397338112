import {
  SET_SEARCH_STATUS,
  SET_SEARCH_STRING,
  LOAD_SEARCH_RESULTS
} from '../constants/action-types';

import {
  TYPE_ISSUE,
  TYPE_CATEGORY,
  TYPE_CAMPUS_GUIDANCE,
  TYPE_TAG
} from '../constants';

import {
  setErrors,
  resetAllMessages
} from './';

import { SEARCH_ROUTE } from '../constants/routes';
import history from '../history';

import { searchRecords } from '../api';


const setSearchString = searchString => ({
  type: SET_SEARCH_STRING,
  searchString
});

const setSearchStatus = isSearching => ({
  type: SET_SEARCH_STATUS,
  isSearching
});

const loadSearchResults = data => ({
  type: LOAD_SEARCH_RESULTS,
  data
});

const clearSearchResults = () => loadSearchResults({
  [TYPE_ISSUE]: [],
  [TYPE_CAMPUS_GUIDANCE]: [],
  [TYPE_CATEGORY]: [],
  [TYPE_TAG]: []
});

export const executeSearch = (searchString, searchTypes = []) => dispatch => {
  dispatch(clearSearchResults());
  dispatch(setSearchStatus(true));
  dispatch(resetAllMessages());
  dispatch(setSearchString(searchString));
  history.push(SEARCH_ROUTE);

  searchRecords(searchString, searchTypes).then(res => {
    dispatch(loadSearchResults(res));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSearchStatus(false));
  });
};
