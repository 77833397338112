import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.scss";

import { CategoryList, PageContent, PageContainer } from "../../components";

import { WELCOME_BLURB, WELCOME_BLURB_UNAUTHED } from "../../constants/fragments";

const mapStateToProps = state => ({
  isLoggedIn: Boolean(state.session.currentUser) && Boolean(state.session.currentUser.role)
});

class Welcome extends Component {
  render() {
    return (
      <PageContainer className="welcome-screen">
        <CategoryList />
        <PageContent>
          <h1>Welcome to the Negotiator's Handbook</h1>
          { this.props.isLoggedIn ? WELCOME_BLURB : WELCOME_BLURB_UNAUTHED }
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps)(Welcome);
