import React, { Component, Fragment, createRef } from "react";
import "./style.scss";
import { Modal } from "../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList
} from "../../components/widgets";

import {
  resetAllMessages,
  resetErrors,
  attemptToUpdateUserProfile,
} from "../../actions";

import { connect } from "react-redux";

import history from "../../history";

import { getCurrentUser } from "../../selectors";
import { ROOT_ROUTE } from "../../constants/routes";

import Auth from '../../auth';

const mapStateToProps = (state, props) => {
  return {
    shouldLockForm: state.users.actions.isLoading,
    shouldLockSubmit: state.users.actions.isLoading || state.campuses.isLoading,
    errors: state.messages.errors,
    currentUser: getCurrentUser(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: userData => dispatch(attemptToUpdateUserProfile({ ...userData })),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      dispatch(resetErrors());
      history.replace(ROOT_ROUTE);
    },
    signOut: () => {
      Auth.signOut();
    },
  };
};

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.focusName = createRef();
    this.focusRole = createRef();
  }

  componentDidMount() {
    this.props.resetMessages();

    // TODO this doesn't work if we don't have the users list loaded yet
    this.focusName.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    // this will close the modal in the case when someone goes directly to the edit route for a non-confirmed user
    //if (this.props.user.email && !this.props.user.confirmed) {
      //this.props.hideModal();
    //}
  }

  render() {
    const {
      hideModal,
      onSubmit,
      shouldLockForm,
      shouldLockSubmit,
      errors,
      currentUser,
      signOut
    } = this.props;
    const confirmMessage = currentUser.confirmed ? 'Update' : 'Submit';
    const isNameSet = currentUser.preferredFirstName && currentUser.preferredFirstName;
    const cancelMessage = isNameSet ? 'Cancel' : 'Sign Out';
    const title = isNameSet ? 'Edit your name' : 'You will need to enter your name to use the application'
    return (
      <Modal handleClose={isNameSet? hideModal : signOut}>
        <Form onSubmit={onSubmit} className={"user-action-modal"}>
          <h2>{title}</h2>
          <ErrorList errors={errors} />
          <Fragment>
            <TextInput
              focusRef={this.focusName}
              initialValue={isNameSet ? currentUser.preferredFirstName : ""}
              name="firstname"
              label="First Name*"
              type="text"
              required={true}
              disabled={shouldLockForm}
            />
            <TextInput
              initialValue={isNameSet ? currentUser.preferredLastName : ""}
              name="lastname"
              label="Last Name*"
              type="text"
              required={true}
              disabled={shouldLockForm}
            />
          </Fragment>
          <Fragment>
            <input
              type="hidden"
              name="role"
              value={currentUser.role}
            />
            <input
              type="hidden"
              name="campusid"
              value={currentUser.campusId}
            />
            <input
              type="hidden"
              name="id"
              value={currentUser.userName}
            />
          </Fragment>
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockSubmit}
            >
              {shouldLockForm ? <Spinner light /> : confirmMessage}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={isNameSet? hideModal : signOut}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : cancelMessage}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);
