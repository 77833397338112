/**
 * @module api/categories
 * @desc Category related api functions.
 */

import { gqlQuery } from './';
import {
  CATEGORY_LIST_ERROR,
  CATEGORY_VIEW_ERROR,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_CREATE_ERROR,
  GQL_NULL_RESPONSE_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import {
  isDev
} from '../utils';

// TODO we need to get issue counts for the category query
const LIST_CATEGORY_QUERY =`
query ListCategories {
  listCategorys (limit: 1000) {
    items {
      id,
      name
      records {
        items {
          id
        }
      }
    }
  }
}`;

/**
 * Makes an authed call to the graphql endpoint to retrieve a list of campuses
 * @function
 * @returns {Promise<categories[]|errors[]>}
 */
export const fetchCategoryList = () => gqlQuery(LIST_CATEGORY_QUERY)
  .then(res => {
    if (!res.listCategorys.items) {
      throw(GQL_NULL_RESPONSE_ERROR);
    }
    return res.listCategorys.items
  })
  .catch(res => {
    const errors = [
      CATEGORY_LIST_ERROR,
      makeGenericErrorMessage({context: 'category list', error: res.errors})
    ];
    throw(errors);
  });

if (isDev()) {
  window.fetchCategoryList = () => {
    fetchCategoryList().then(console.log, console.log);
  };
}


const GET_CATEGORY_ISSUE_NAMES_QUERY = `
query ($id: ID!) {
  getCategory (id: $id) {
    records {
      items {
        title,
        id,
        published
      }
    }
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to retrieve a list of issues for a given category
 * @function
 * @param {string} categoryId
 * @returns {Promise<issues[]|errors[]>}
 */
export const fetchIssueListForCategory = categoryId =>
  gqlQuery(GET_CATEGORY_ISSUE_NAMES_QUERY, {id: categoryId})
    .then(res => {
      if (!(res.getCategory.records && res.getCategory.records.items)) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.getCategory.records.items
    })
    .catch(res => {
      const errors = [
        CATEGORY_VIEW_ERROR,
        makeGenericErrorMessage({context: 'issue list for category', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.fetchIssueListForCategory = categoryId => {
    fetchIssueListForCategory(categoryId).then(console.log, console.log);
  };
}

const UPDATE_CATEGORY_MUTATION = `
mutation UpdateCategory ($id: ID!, $name: String!){
  updateCategory(input: {
    id: $id,
    name: $name
  }) {
    id,
    name
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to update a category's name
 * @function
 * @param {string} categoryData
 * @param {string} categoryData.id
 * @param {string} categoryData.name
 * @returns {Promise<category|errors[]>}
 */
export const updateCategory = categoryData =>
  gqlQuery(UPDATE_CATEGORY_MUTATION, {id: categoryData.id, name: categoryData.name.trim()})
    .then(res => {
      if (!res.updateCategory) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.updateCategory
    })
    .catch(res => {
      const errors = [
        CATEGORY_UPDATE_ERROR,
        makeGenericErrorMessage({context: 'category update', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.updateCategory = categoryData => {
    updateCategory(categoryData).then(console.log, console.log);
  };
}

const CREATE_CATEGORY_MUTATION = `
mutation CreateCategory ($name: String!){
  createCategory(input:{
    name: $name
  }) {
    id
    name
  }
}
`;

/**
 * Makes an authed call to the graphql endpoint to create a new category
 * @function
 * @param {string} categoryData
 * @param {string} categoryData.name
 * @returns {Promise<category|errors[]>}
 */
export const createCategory = categoryData =>
  gqlQuery(CREATE_CATEGORY_MUTATION, { name: categoryData.name.trim() })
    .then(res => {
      if (!res.createCategory) {
        throw(GQL_NULL_RESPONSE_ERROR);
      }
      return res.createCategory;
    })
    .catch(res => {
      const errors = [
        CATEGORY_CREATE_ERROR,
        makeGenericErrorMessage({context: 'category create', error: res.errors})
      ];
      throw(errors);
    });

if (isDev()) {
  window.createCategory = categoryData => {
    createCategory(categoryData).then(console.log, console.log);
  };
}
