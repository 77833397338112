import React, { Component, Fragment } from "react";
import "./style.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { makeClassName } from "../../utils"

class IssueNav extends Component {
  render() {
    const { visibleSection, campusGuidanceList, showVersionMenuItem } = this.props;
    return (
      <section className="issue-nav">
        <ul>
          <li className={makeClassName('issue-nav-main', visibleSection === "ucop-guidance" ? 'nav-active-section' : '')}>
            <AnchorLink offset="65" href="#ucop-guidance">
              UCOP Guidance
              <i className="fas fa-angle-right nav-caret" />
            </AnchorLink>
          </li>
          <li className={makeClassName('issue-nav-main', visibleSection === "rationale" ? 'nav-active-section' : '')}>
            <AnchorLink offset="65" href="#rationale">
              Rationale
              <i className="fas fa-angle-right nav-caret" />
            </AnchorLink>
          </li>
          <li className={makeClassName('issue-nav-main', visibleSection === "agreement-language" ? 'nav-active-section' : '')}>
            <AnchorLink offset="65" href="#agreement-language">
              Agreement Language
              <i className="fas fa-angle-right nav-caret" />
            </AnchorLink>
          </li>
          { campusGuidanceList.length > 0 &&
              <Fragment>
                <li className="issue-nav-sub-head">
                  Campus Guidance
                </li>
                {
                  campusGuidanceList.map((guidanceData, idx) => {
                    const { campus } = guidanceData;
                    return (
                      <li
                        key={campus.id}
                        className={makeClassName(
                          "issue-nav-main",
                          (visibleSection === `${campus.short_name}-guidance`) ?
                            'nav-active-section' : ''
                        )}
                        >

                        <AnchorLink offset="65" href={`#${campus.short_name}-guidance`}>
                          {campus.long_name}
                          <i className="fas fa-angle-right nav-caret" />
                        </AnchorLink>
                      </li>
                    )
                  })
                }
              </Fragment>
          }
          {
            showVersionMenuItem &&
              <li className={makeClassName("issue-nav-main", "previous-version-menu-item", visibleSection === "previous-version-list" ? "nav-active-section" : "")}>
                <AnchorLink offset="65" href="#previous-version-list">
                  Previous Versions
                  <i className="fas fa-angle-right nav-caret" />
                </AnchorLink>
              </li>
          }
        </ul>
      </section>
    );
  }
}

export default IssueNav;
