import React, { Component, createRef } from "react";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { TAG_LIST_ROUTE } from "../../../constants/routes";

import { resetAllMessages, resetErrors } from "../../../actions";

import { connect } from "react-redux";
import history from "../../../history";

import { getTagById } from "../../../selectors";
import { attemptToUpdateTag } from "../../../actions";

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;

  return {
    tag: getTagById(id)(state),
    shouldLockForm:
      state.tags.list.isLoading || state.tags.actions.isLoading,
    errors: state.messages.errors
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.match.params;
  return {
    onSubmit: tagData =>
      dispatch(attemptToUpdateTag({ ...tagData, id })),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(TAG_LIST_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class EditTag extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }

  componentDidMount() {
    this.props.resetMessages();
    this.focusRef.current.focus();
  }

  render() {
    const {
      hideModal,
      onSubmit,
      shouldLockForm,
      errors,
      tag
    } = this.props;

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"ucop-form-edittag"}>
          <h2>Edit {tag ? tag.name : "Tag"}</h2>
          <br />
          <ErrorList errors={errors} />
          <TextInput
            focusRef={this.focusRef}
            name="name"
            label="Tag Name*"
            type="text"
            initialValue={tag ? tag.name : ""}
            required={true}
            disabled={shouldLockForm}
          />
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner light /> : "Save"}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTag);
