import {
  SET_SEARCH_STATUS,
  SET_SEARCH_STRING,
  LOAD_SEARCH_RESULTS
} from '../constants/action-types';

import {
  TYPE_ISSUE,
  TYPE_CATEGORY,
  TYPE_CAMPUS_GUIDANCE,
  TYPE_TAG
} from '../constants';

const initialState = {
  data: {
    [TYPE_ISSUE]: [],
    [TYPE_CAMPUS_GUIDANCE]: [],
    [TYPE_CATEGORY]: [],
    [TYPE_TAG]: []
  },
  isSearching: false,
  searchString: ""
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_STATUS:
      return {
        ...state,
        isSearching: action.isSearching
      };

    case LOAD_SEARCH_RESULTS:
      return {
        ...state,
        data: action.data
      };

    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };

    default:
      return state;
  }
}
