/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:19ce8051-3f93-420d-9c59-c9f82a3e0bb1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_SlC1RbWsp",
    "aws_user_pools_web_client_id": "1ogmdrbltu42jg7fag7ekccj05",
    "oauth": {
        "domain": "was-nhb-newprod.auth.us-west-2.amazoncognito.com",
        "redirectSignIn": "https://negotiators-handbook.ucop.edu/",
        "redirectSignOut": "https://negotiators-handbook.ucop.edu/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://grkkn7po5bel5cqqak7xxaecye.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "nhbusersapi",
            "endpoint": "https://vmvcuws4v8.execute-api.us-west-2.amazonaws.com/newprod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
