export const MISSING_EMAIL_ERROR = 'Email cannot be blank';
export const MISSING_PASSWORD_ERROR = 'Password cannot be blank';
export const INVALID_LOGIN_ERROR = 'Your username or password is incorrect';
export const INVALID_RESET_CODE_ERROR = 'Your reset code is invalid';
export const MISSING_RESET_CODE_ERROR = 'Reset code cannot be blank';

export const MISSING_PASSWORD_CONFIRMATION_ERROR = 'Please confirm your password';
export const MISMATCHED_PASSWORD_ERROR = 'Passwords must match';
export const WEAK_PASSWORD_ERROR = 'Please ensure that your password meets the given criteria';

export const EMAIL_NOT_FOUND_ERROR = 'We can\'t find an account with that email address';

export const UNABLE_TO_LOGIN_ERROR = 'We are unable to log you in at this time.';
export const UNABLE_TO_RESET_PASSWORD_ERROR = 'We are unable to reset you password at this time.';
export const UNABLE_TO_CHANGE_PASSWORD_ERROR = 'We are unable to reset you password at this time.';
export const UNABLE_TO_LOAD_USER_ERROR = 'We are unable to load your user info at this time.';
