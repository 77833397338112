export const ISSUE_LIST_ERROR = 'We are unable to load the issue list at this time';
export const CREATE_ISSUE_ERROR = 'We are unable to create this issue at this time';
export const UPDATE_ISSUE_ERROR = 'We are unable to update this issue at this time';
export const SINGLE_ISSUE_ERROR = 'We are unable to load this issue at this time';
export const PREVIOUS_VERSION_ERROR = 'We are unable to load this version at this time';
export const MISSING_ISSUE_TITLE_ERROR = 'Please add a title for this issue';
export const MISSING_ISSUE_CATEGORY_ERROR = 'Please select a category for this issue';
export const MISSING_ISSUE_GUIDANCE_ERROR = 'Please add UCOP guidance to this issue';
export const MISSING_ISSUE_RATIONALE_ERROR = 'Please add rationale to this issue';
export const MISSING_ISSUE_AGREEMENT_LANGUAGE_ERROR = 'Please add agreement language to this issue';
