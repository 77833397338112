import React, { Component } from 'react';
import './style.scss';

import { makeClassName } from '../../../utils';

const parseOption = option => {
  const data = {};
  if (typeof option === 'object') {
    data.name = option.name;
    data.value = option.value
  } else {
    data.name = option;
  }

  if (typeof data.value === 'undefined') {
    data.value = data.name.toLowerCase();
  }


  return data;
};

class Select extends Component {
  state = {
    value: this.props.initialValue
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialValue && !prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue
      })
    }
  }

  onChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }

    this.setState({
      value: event.target.value
    });
  }

  render() {
    const { focusRef, name, id, className, label, disabled, required, options, prompt } = this.props;

    return (
      <label className={makeClassName('ucop-select', className)}>
        {label}
        <select
          ref={focusRef}
          name={name}
          id={id}
          value={this.state.value}
          onChange={this.onChange}
          disabled={disabled}
          required={required}
        >
          {prompt && <option value="">{prompt}</option>}
          {
            options.map((option, idx) => {
              const data = parseOption(option);
              return (
                <option key={idx} value={data.value}>{data.name}</option>
              );
            })
          }
        </select>
      </label>

    );
  }


}

export default Select;
