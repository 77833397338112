/**
 * @module actions/session-actions
 * @desc Redux and thunk actions to handle session/login/logout related things
// NOTE signing a user in and out is handled primarily by the Auth.js module. These actions are
// for adding session status info to the store and verifying its presence
 */
import {
  LOGOUT_USER,
  SET_CURRENT_USER_ATTRIBUTES,
} from "../constants/action-types";

import {
  getCurrentCognitoUser,
  getCurrentCognitoUserAttributes,
} from "../api";


import {
  resetErrors,
  setErrors
} from './';

import ReactGA from 'react-ga4';

/////////////////////
// ACTION CREATORS //
/////////////////////

const logout = () => ({ type: LOGOUT_USER });

const setCurrentUserAttributes = attributes => {
  ReactGA.set({userId: attributes.sub});
  return {
    type: SET_CURRENT_USER_ATTRIBUTES,
    attributes
  };
};

/////////////
// ACTIONS //
/////////////



/**
 * Action to load an SSO user's into the Redux store. This gets called by Auth.js when the user logs in
 * @function
*/
export const initializeUserSessionFromSSO = () => dispatch => {
  getCurrentCognitoUserAttributes().then(attributes => {
    dispatch(setCurrentUserAttributes(attributes));
  }).catch(errors => {
    dispatch(setErrors(errors))
    dispatch(endSession());
  }).finally(() => {
  });

};

/**
 * Action to clear the store. This has the affect of logging the current user out.
 * @function
 */
export const endSession = () => dispatch => {
  dispatch(logout());
  dispatch(resetErrors());
};

/**
 * Action to check if we have an active session. This is used to load user data into the store if we have a valid session
 * but not a 'currentUser'. This should typically only happen when a logged in users loads/refreshes the page.
 * This action also will redirect to the login screen if the user does not have a valid session. This part
 * of the action is used in the authenticated route component.
 * If we have a cognito user but no attributes we try to get them. If we have no cognito user we log out.
 * @function
 */
export const verifyCurrentUserSession = () => (dispatch, getState) => {
  const cognitoUser = getCurrentCognitoUser();
  if (cognitoUser) {
    // if we have a cognito user but no current user in the store then we need to try and add one
    // This could happen if a user logs in on another tab
    if (!getState().session.currentUser) {
      dispatch(initializeUserSessionFromSSO());
    }
  } else {
    // if we are not able to get a cognito user then we are for sure logged out
    // This can happen if a user logs out in another tab
    dispatch(endSession());
  }
};

/**
 * 
 * @param {*} userData
 * @param userdData.firstname
 * @param userdData.lastname
 * @returns 
 */
export const updateUserName = (userData) => (dispatch, getState) => {
  const { firstname, lastname } = userData;
  const currentUser = getState().session.currentUser;
  const updatedUser = {
    ...currentUser,
    given_name: firstname,
    family_name: lastname,
    "custom:preferred_last_name": lastname,
    "custom:preferred_first_name": firstname,
  };
  dispatch(setCurrentUserAttributes(updatedUser));

  const cognitoUser = getCurrentCognitoUser();
  cognitoUser.getSession((error, session) => {
    cognitoUser.refreshSession(session.refreshToken, (err, session) => {
      if (err) throw err;
    });  
  });    
};
