import { combineReducers } from 'redux';

import {
  LOAD_ISSUE_LIST,
  SET_ISSUE_LIST_LOADING_STATUS,
  LOAD_SINGLE_ISSUE,
  SET_SINGLE_ISSUE_LOADING_STATUS,
  SET_SINGLE_ISSUE_UPDATING_STATUS,
  LOGOUT_USER,
  SET_NAV_SECTION_VISIBLE,
  SET_SECTION_EDIT_STATUS,
  LOAD_PREVIOUS_VERSION
} from '../constants/action-types';

const listInitialState = {
  data: [],
  loaded: false,
  isLoading: false
};

function list(state = listInitialState, action) {
  switch (action.type) {

    case LOAD_ISSUE_LIST:
      return {
        ...state,
        data: action.data,
        loaded: true,
        isLoading: false
      };

    case SET_ISSUE_LIST_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case LOGOUT_USER:
      return listInitialState;

    default:
      return state;
  }
}

const currentIssueInitialState = {
  data: null,
  previousVersion: null,
  isLoading: false,
  isUpdating: false,
  visibleSection: false,
  sectionEditStatus: {
    title: false,
    guidance: false,
    rationale: false,
    agreement_language: false,
  }
};

function currentIssue(state = currentIssueInitialState, action) {
  switch (action.type) {

    case LOAD_SINGLE_ISSUE:
      return {
        ...state,
        data: action.data
      };

    case SET_SINGLE_ISSUE_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case SET_SINGLE_ISSUE_UPDATING_STATUS:
      return {
        ...state,
        isUpdating: action.isUpdating
      };

    case SET_NAV_SECTION_VISIBLE:
      return{
        ...state,
        visibleSection: action.visible
      };

    case SET_SECTION_EDIT_STATUS:
      return {
        ...state,
        sectionEditStatus: {
          ...state.sectionEditStatus,
          [action.section]: action.status
        }
      };

    case LOAD_PREVIOUS_VERSION: {
      return {
        ...state,
        previousVersion: action.previousVersion
      };
    }

    default:
      return state;
  }
}

export default combineReducers({
  list,
  currentIssue
});

