export const TAG_LIST_ERROR =
  "We are unable to load the tag list at this time";
export const SINGLE_TAG_ERROR =
  "We are unable to load the tag at this time";
export const TAG_CREATE_ERROR =
  "We are unable to create the tag at this time";
export const TAG_UPDATE_ERROR =
  "We are unable to update the tag at this time";
export const TAG_DELETE_ERROR =
  "We are unable to delete the tag at this time";

export const MISSING_TAG_NAME_ERROR = "Please provide a tag name";
export const DUPLICATE_TAG_NAME_ERROR = "Tag name already exists. Please enter a different tag name";
