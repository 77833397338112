import {
  LOGOUT_USER,
  SET_CURRENT_USER_ATTRIBUTES,
} from "../constants/action-types";

const initialState = {
  loginErrors: [],
  currentUser: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER_ATTRIBUTES:
      return {
        ...state,
        currentUser: action.attributes
      };

    case LOGOUT_USER:
      return { ...initialState, isLoadingSession: false };

    default:
      return state;
  }
}
