import React, { Component, createRef } from "react";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { CATEGORY_LIST_ROUTE } from "../../../constants/routes";

import { resetAllMessages, resetErrors } from "../../../actions";

import { connect } from "react-redux";
import history from "../../../history";

import { getCategoryById } from "../../../selectors";
import { attemptToUpdateCategory } from "../../../actions";

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;

  return {
    category: getCategoryById(id)(state),
    shouldLockForm:
      state.categories.list.isLoading || state.categories.actions.isLoading,
    errors: state.messages.errors
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.match.params;
  return {
    onSubmit: categoryData =>
      dispatch(attemptToUpdateCategory({ ...categoryData, id })),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(CATEGORY_LIST_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }
  componentDidMount() {
    this.props.resetMessages();
    this.focusRef.current.focus();
  }

  render() {
    const {
      hideModal,
      onSubmit,
      shouldLockForm,
      errors,
      category
    } = this.props;

    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"ucop-form-editcategory"}>
          <h2>Edit {category ? category.name : "Category"}</h2>
          <br />
          <ErrorList errors={errors} />
          <TextInput
            focusRef={this.focusRef}
            name="name"
            label="Category Name*"
            type="text"
            initialValue={category ? category.name : ""}
            required={true}
            disabled={shouldLockForm}
          />
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner light /> : "Update"}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategory);
