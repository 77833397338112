import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

import { APP_TITLE } from "./constants";

import history from "./history";

import {
  ROOT_ROUTE,
  ISSUE_ROUTE,
  ISSUE_VERSION_ROUTE,
  LOGIN_ROUTE,
  UNREGISTERED_ROUTE,
  CATEGORY_ROUTE,
  USERS_ROUTE,
  CATEGORY_LIST_ROUTE,
  ISSUE_LIST_ROUTE,
  TAG_LIST_ROUTE,
  SEARCH_ROUTE,
  PROFILE_EDIT_ROUTE,
} from "./constants/routes";

import {
  ROLE_SYSTEM_ADMINISTRATOR,
  ROLE_LOCATION_ACCESS_COORDINATOR,
} from "./constants";

import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  Header,
  Footer
} from "./components";

import {
  LoginForm,
  Unregistered,
  Welcome,
  CategoryView,
  Issue,
  Users,
  CategoryAdminList,
  IssueAdminList,
  TagsAdminList,
  Search,
  EditProfile
} from "./pages";

import { verifyCurrentUserSession } from "./actions";

class App extends Component {
  componentDidMount() {
    document.title = APP_TITLE;
    this.props.verifyCurrentUserSession();
  }

  renderApp() {
    const { isLoggedIn } = this.props;

    return (
      <Router history={history}>
        <div
          className={
            isLoggedIn ? "app-container private" : "app-container public"
          }
        >
          {isLoggedIn && <Header />}
          <Switch>
            <UnauthenticatedRoute exact path={LOGIN_ROUTE} component={LoginForm} />

            <Route exact path={UNREGISTERED_ROUTE} component={Unregistered} />

            <AuthenticatedRoute exact path={ROOT_ROUTE} component={Welcome} />
            <AuthenticatedRoute exact path={ISSUE_ROUTE} component={Issue} />
            <AuthenticatedRoute exact path={ISSUE_VERSION_ROUTE} component={Issue} />
            <AuthenticatedRoute
              path={CATEGORY_ROUTE}
              component={CategoryView}
            />
            <AuthenticatedRoute
              path={USERS_ROUTE}
              component={Users}
              allowedRoles={[
                ROLE_SYSTEM_ADMINISTRATOR,
                ROLE_LOCATION_ACCESS_COORDINATOR
              ]}
            />
            <AuthenticatedRoute
              path={CATEGORY_LIST_ROUTE}
              component={CategoryAdminList}
              allowedRoles={[
                ROLE_SYSTEM_ADMINISTRATOR
              ]}
            />
            <AuthenticatedRoute
              path={ISSUE_LIST_ROUTE}
              component={IssueAdminList}
              allowedRoles={[
                ROLE_SYSTEM_ADMINISTRATOR
              ]}
            />
            <AuthenticatedRoute
              path={TAG_LIST_ROUTE}
              component={TagsAdminList}
              allowedRoles={[
                ROLE_SYSTEM_ADMINISTRATOR
              ]}
            />
            <AuthenticatedRoute
              path={SEARCH_ROUTE}
              component={Search}
            />
            <AuthenticatedRoute
              path={PROFILE_EDIT_ROUTE}
              component={EditProfile}
            />            
            {/* This is a catch-all route so we can redirect to the dashboard for all unhandled urls */}
            {/* It needs to be at the end so we don't match a real route by mistake */}
            <UnauthenticatedRoute path="" />
          </Switch>
          {isLoggedIn ? <Footer /> : null}
        </div>
      </Router>
    );
  }

  render() {
    const { isLoadingSession } = this.props;
    return isLoadingSession ? null : this.renderApp();
  }
}

const mapStateToProps = state => ({
  isLoadingSession: state.session.isLoadingSession,
  isLoggedIn: Boolean(state.session.currentUser) && Boolean(state.session.currentUser.role)
});

export default connect(
  mapStateToProps,
  {
    verifyCurrentUserSession
  }
)(App);
