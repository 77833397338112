/**
 * @module utils/users
 * @desc validators, parsers, etc reltaed to user data
 */

import {
  SHORT_NAME_ERROR,
  MISSING_ROLE_ERROR,
  MISSING_CAMPUS_ERROR,
  MISSING_NAME_ERROR,
  UNABLE_TO_UPDATE_USER_ERROR,
  UNABLE_TO_DELETE_USER_ERROR,
  DELETE_USER_NO_CONFIRMATION_ERROR,
  makeGenericErrorMessage
} from "../constants/errors";

import {
  ROLES
} from '../constants';

import { isEmptyString } from './';

/**
 * Checks update user form data for errors
 * @param {Object} formData
 * @param {string} formData.id
 * @param {string} formData.firstname
 * @param {string} formData.lastname
 * @param {string} formData.role
 * @param {string} formData.campus
 * @param {string[]} campusList
 * @returns {string[]} errors
 */
export function validateUpdateUserFormData(formData, campusList) {
  const errors = [];
  const { id, firstname, lastname, role, campusid } = formData;

  if (isEmptyString(id)) {
    errors.push([
      UNABLE_TO_UPDATE_USER_ERROR,
      makeGenericErrorMessage({context: 'update user', error: 'missing id'})
    ]);
  }

  if (isEmptyString(firstname) || isEmptyString(lastname)) {
    errors.push(MISSING_NAME_ERROR);
  } else if ((firstname.length < 2) || (lastname.length < 2)) {
    errors.push(SHORT_NAME_ERROR);
  }

  if (!ROLES.find(roleData => role === roleData.value)) {
    errors.push(MISSING_ROLE_ERROR)
  }

  if (!campusList.find(campus => campusid === campus.id)) {
    errors.push(MISSING_CAMPUS_ERROR)
  }

  return errors;
}

/**
 * Checks update user form data for errors
 * @param {Object} formData
 * @param {string} formData.id
 * @param {string} formData.firstname
 * @param {string} formData.lastname
 * @returns {string[]} errors
 */
 export function validateUpdateUserProfileFormData(formData) {
  const errors = [];
  const { id, firstname, lastname } = formData;

  if (isEmptyString(id)) {
    errors.push([
      UNABLE_TO_UPDATE_USER_ERROR,
      makeGenericErrorMessage({context: 'update user', error: 'missing id'})
    ]);
  }

  if (isEmptyString(firstname) || isEmptyString(lastname)) {
    errors.push(MISSING_NAME_ERROR);
  } else if ((firstname.length < 2) || (lastname.length < 2)) {
    errors.push(SHORT_NAME_ERROR);
  }

  return errors;
}

/**
 * Checks delete user form data for errors
 * @param {Object} formData
 * @param {string} formData.id
 * @param {string} formData.email email entered in form to confirm deletion
 * @param {string} userEmail email taken from user data in store
 *
 * @returns {string[]} errors
 */
export function validateDeleteUserFormData(formData, userEmail) {
  const errors = [];
  const { id, email } = formData;

  if (isEmptyString(id) || isEmptyString(userEmail)) {
    errors.push([
      UNABLE_TO_DELETE_USER_ERROR,
      makeGenericErrorMessage({context: 'delete user', error: 'missing id'})
    ]);
  }

  if (isEmptyString(email) || (email.trim() !== userEmail.trim())) {
    errors.push(DELETE_USER_NO_CONFIRMATION_ERROR);
  }

  return errors;
}

const ROLE_LOOKUP = ROLES.reduce((lookup, role) => {
  lookup[role.value] = role.name;

  return lookup;
}, {});

export const getRoleName = roleValue => ROLE_LOOKUP[roleValue];

/**
 * Converts user data from the users endpoint into a format better suited for the app
 * @param {object} rawUserData
 * @param {string} rawUserData.Username
 * @param {string} rawUserData.UserCreateDate
 * @param {string} rawUserData.UserLastModifiedDate
 * @param {string} rawUserData.UserStatus
 * @param {string} rawUserData.role
 * @param {object[]} rawUserData.Attributes
 * @returns {object} user
 * @returns {string} user.id
 * @returns {string} user.createdAt
 * @returns {string} user.updatedAt
 * @returns {string} user.status
 * @returns {string} user.role
 * @returns {string} user.campus
 * @returns {string} user.family_name
 * @returns {string} user.given_name
 */
export function parseUserResponse(rawUserData) {
  // TODO create actual date objects out of date strings from response
  const user = {
    id: rawUserData.Username,
    createdAt: rawUserData.UserCreateDate,
    updatedAt: rawUserData.UserLastModifiedDate,
    status: rawUserData.UserStatus,
    role: rawUserData.role
  };

  if (rawUserData.Attributes) {
    rawUserData.Attributes.forEach(({Name, Value}) => {
      if (Name.search('custom:') === 0) {
        Name = Name.substring(7); // 7 chars in 'custom:'
      }
      user[Name] = Value;
    });
  }

  return user;
}
