import React from "react";
import "./style.scss";

import { Link } from "react-router-dom";

import {
  USERS_ROUTE,
  CATEGORY_LIST_ROUTE,
  ISSUE_LIST_ROUTE,
  TAG_LIST_ROUTE
} from "../../constants/routes";

const LeftsideMenu = props => {
  const { path } = props;

  return (
    <div className="leftside-menu">
      <ul>
        <h3>Menu</h3>
        <li className={path === USERS_ROUTE ? "active" : null}>
          <Link to={USERS_ROUTE}>
            <i className="fa fa-users" title="users menu" />
            Users
          </Link>
        </li>
        <li className={path === ISSUE_LIST_ROUTE ? "active" : null}>
          <Link to={ISSUE_LIST_ROUTE}>
            <i className="far fa-copy" title="Issues menu" />
            Issues
          </Link>
        </li>
        <li className={path === CATEGORY_LIST_ROUTE ? "active" : null}>
          <Link to={CATEGORY_LIST_ROUTE}>
            <i className="fa fa-list-alt" title="categories menu" />
            Categories
          </Link>
        </li>
        <li className={path === TAG_LIST_ROUTE ? "active" : null}>
          <Link to={TAG_LIST_ROUTE}>
            <i className="fa fa-tag" title="tags menu" />
            Tags
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftsideMenu;
