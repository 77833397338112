import React, { Component } from "react";
import {
  AuthenticatedRoute,
  PageContainer,
  PageContent,
  LeftsideMenu
} from "../../../components";

import { Spinner } from "../../../components/widgets";
import IssueAdminList from "./issues-admin-list";

import { connect } from "react-redux";
import {
  resetAllMessages,
  fetchAndLoadIssueList,
  fetchAndLoadCategoryList
} from "../../../actions";

import {
  ISSUE_LIST_ROUTE,
  ISSUE_CREATE_ROUTE
} from "../../../constants/routes";

import {
  ROLE_SYSTEM_ADMINISTRATOR
} from "../../../constants";

import CreateIssueModal from "./create-issue-modal";

const mapStateToProps = (state, props) => ({
  isLoading: state.issues.list.isLoading
});

class Issues extends Component {
  componentDidMount() {
    this.props.resetAllMessages();
    this.props.fetchAndLoadIssueList();
    this.props.fetchAndLoadCategoryList();
  }

  componentWillUnmount() {
    this.props.resetAllMessages();
  }

  render() {
    const { isLoading } = this.props;
    return (
      <PageContainer className="issue-management">
        <AuthenticatedRoute
          path={ISSUE_CREATE_ROUTE}
          component={CreateIssueModal}
          allowedRoles={[ ROLE_SYSTEM_ADMINISTRATOR ]}
        />
        <LeftsideMenu path={ISSUE_LIST_ROUTE} />
        <PageContent>
          {isLoading ? <Spinner /> : <IssueAdminList />}
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    resetAllMessages,
    fetchAndLoadIssueList,
    fetchAndLoadCategoryList
  }
)(Issues);
