import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  setNavSectionVisible,
} from "../../actions";

import {
  ISSUE_ROUTE_PREFIX,
  ISSUE_VERSION_ROUTE_PREFIX
} from "../../constants/routes";

import IssueSectionContainer from "./issue-section-container";

const mapStateToProps = (state, props) => {
  return {
    visibleSection: state.issues.currentIssue.visibleSection
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNavSectionVisible: id => dispatch(setNavSectionVisible(id)),
  };
};

const makeVersionString = version => `Version last updated by ${version.editor} on ${version.dateString} at ${version.timeString}`;

class PreviousVersionList extends Component {
  render() {
    const { id, previousVersions, currentIssueId, versionId } = this.props;

    return (
      <IssueSectionContainer
        title="Previous Versions"
        id={id}
        showCollapseButton
      >
        {
          previousVersions.length > 1 ?
            <ul className="previous-version-list">
              {
                previousVersions.slice(1).map(version => (
                  <li key={version.id}>
                    {
                      version.id === versionId ?
                      <span>{makeVersionString(version)}</span>
                      :
                      <Link to={ISSUE_ROUTE_PREFIX + currentIssueId + ISSUE_VERSION_ROUTE_PREFIX + version.id}>
                        {makeVersionString(version)}
                      </Link>
                    }
                  </li>
                ))
              }
            </ul>
            :
            <p>There are no previous versions</p>
        }
      </IssueSectionContainer>
    );
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousVersionList);
