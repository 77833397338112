import React, { Component, createRef } from "react";
import { Button } from "../../components/widgets";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {
  ISSUE_ROUTE_PREFIX,
  ROOT_ROUTE,
  CATEGORY_ROUTE_PREFIX
} from "../../constants/routes";

import { makeIssueNameWithStatus, makeClassName } from "../../utils";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleBtop: false
    };

    this.focusRef = createRef();
  }
  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.previousVersion &&
      this.props.previousVersion &&
      this.focusRef.current
    ) {
      this.focusRef.current.focus();
      window.scrollTo(0,0);
    }
  }

  trackScrolling = () => {
    if (window.scrollY > 200 && !this.state.toggleBtop) {
      this.setState({
        toggleBtop: true
      });
    }
    if (window.scrollY <= 200 && this.state.toggleBtop) {
      this.setState({
        toggleBtop: false
      });
    }
  };

  render() {
    const { issue, previousVersion, activateVersionFunction } = this.props;
    const { toggleBtop } = this.state;
    return (
      <div className="issue-breadcrumbs">
        <Link to={ROOT_ROUTE}>Categories</Link>
        <span>></span>
        <Link to={CATEGORY_ROUTE_PREFIX + issue.category.id}>
          {issue.category.name}
        </Link>
        <span>-</span>
        <span>{makeIssueNameWithStatus(issue)}</span>
        <AnchorLink className={makeClassName("back-to-top", toggleBtop ? "back-to-top-activated" : "back-to-top-deactivated")} href="#top">
          <i className="fas fa-arrow-up" /> Back to top
        </AnchorLink>
        { previousVersion &&
            <div className="version-control-container">
              <div className="version-control" ref={this.focusRef} tabIndex="-1">
                <span>{`Currently viewing version created by ${previousVersion.editor} on ${previousVersion.dateString} at ${previousVersion.timeString}`}</span>
                <div className="version-control-buttons">
                  <Button onClick={activateVersionFunction}>Activate this version</Button>
                  <Link to={ISSUE_ROUTE_PREFIX + issue.id}>View current version</Link>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

export default Breadcrumbs;
