import React, { Component, createRef } from "react";
import "./style.scss";
import { Modal } from "../../../components";
import {
  Form,
  TextInput,
  Button,
  Spinner,
  ErrorList
} from "../../../components/widgets";

import { USERS_ROUTE } from "../../../constants/routes";

import {
  resetAllMessages,
  resetErrors,
  attemptToDeleteUser
} from "../../../actions";

import { connect } from "react-redux";

import {
  getUserById
} from '../../../selectors';

import history from "../../../history";

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  const getUser = getUserById(id);

  return {
    user: getUser(state),
    shouldLockForm: state.users.actions.isLoading,
    shouldLockSubmit: state.users.actions.isLoading,
    errors: state.messages.errors
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.match.params;
  return {
    onSubmit: userData => dispatch(attemptToDeleteUser({...userData, id} )),
    resetMessages: () => dispatch(resetAllMessages()),
    hideModal: () => {
      history.replace(USERS_ROUTE);
      dispatch(resetErrors());
    }
  };
};

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }
  componentDidMount() {
    this.props.resetMessages();
    this.focusRef.current.focus();
  }

  render() {
    const {
      hideModal,
      onSubmit,
      shouldLockForm,
      shouldLockSubmit,
      errors
    } = this.props;

    const user = this.props.user || {};
    return (
      <Modal handleClose={hideModal}>
        <Form onSubmit={onSubmit} className={"user-action-modal delete-user"}>
          <h2>Delete {user.email}</h2>
          <ErrorList errors={errors} />
          <br/>
          <p>Please type <span className="email-address">{user.email}</span> to confirm deletion.</p>
          <br/>
          <p>This action is irreversible.</p>
          <TextInput
            focusRef={this.focusRef}
            name="email"
            label="Confirm email*"
            type="email"
            required={true}
            disabled={shouldLockForm}
          />
          <div className="button-container">
            <Button
              type="submit"
              required={true}
              disabled={shouldLockSubmit}
            >
              {shouldLockForm ? <Spinner light /> : "Confirm"}
            </Button>
            <Button
              className="cancel-button"
              required={true}
              onClick={hideModal}
              disabled={shouldLockForm}
            >
              {shouldLockForm ? <Spinner /> : "Cancel"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);
