import moment from 'moment-timezone';
import { createSelector } from 'reselect';

const getCurrentIssue = state => state.issues.currentIssue.data;
const getPreviousVersion = state => state.issues.currentIssue.previousVersion;

const formatVersion = version => {
    const date = version.updated_at ? moment.tz(version.updated_at, moment.tz.guess()) : null;

    return {
      title: version.title,
      editor: version.updated_by || "Unknown",
      id: version.id,
      date: date ? date : null,
      dateString: date ? date.format('M/D/YYYY') : "Unknown",
      timeString: date? date.format('h:mm a z') : "Unknown",
      guidance: version.guidance,
      rationale: version.rationale,
      agreement_language: version.agreement_language
    };
}

export const getFormattedPreviousVersion = createSelector(
  [getPreviousVersion],
  version => {
    if (!version) {
      return null;
    }

    return formatVersion(version);
  }
);

export const getFormattedPreviousVersionList = createSelector(
  [getCurrentIssue],
  issue => {
    if (!(issue && issue.versions && issue.versions.items)) {
      return [];
    }

    return issue.versions.items.map(formatVersion).sort((a, b) => a.date > b.date ? -1 : 1);
  }
);
