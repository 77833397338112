import React from "react";
import { connect } from 'react-redux';

import "./style.scss";

import { makeClassName } from "../../utils";
import { ErrorList, SuccessBanner } from '../widgets';

export const PageContainer = props => {
  const { className } = props;
  return (
    <div className="app-content">
      <div className={makeClassName("page-container", className)}>
        {props.children}
      </div>
    </div>
  );
};

const PageContentComponent = props => {
  const { errors, successMessage, inModal } = props;
  return (
    <div className="page-content" aria-live="polite">
      { !inModal && <ErrorList title="Something went wrong" errors={errors} /> }
      <SuccessBanner message={successMessage} />
      <div className="page-content-children">{props.children}</div>
    </div>
  );
};

export const PageContent = connect(
  state => ({
    errors: state.messages.errors,
    successMessage: state.messages.successMessage,
    inModal: state.messages.inModal
  })
)(PageContentComponent);
