import { combineReducers } from 'redux';

import {
  LOAD_CATEGORY_LIST,
  SET_CATEGORY_LIST_LOADING_STATUS,
  LOAD_CATEGORY_ISSUE_LIST,
  SET_CATEGORY_ISSUE_LIST_LOADING_STATUS,
  SET_CATEGORY_ACTION_LOADING_STATUS,
  LOGOUT_USER
} from '../constants/action-types';

const listInitialState = {
  data: [],
  loaded: false,
  isLoading: false
};

function list(state = listInitialState, action) {
  switch (action.type) {

    case LOAD_CATEGORY_LIST:
      return {
        ...state,
        data: action.data,
        loaded: true,
      };

    case SET_CATEGORY_LIST_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case LOGOUT_USER:
      return listInitialState;

    default:
      return state;
  }
}

const issuesInitialState = {
  data: [],
  isLoading: false
};

function issues(state = issuesInitialState, action) {
  switch (action.type) {

    case LOAD_CATEGORY_ISSUE_LIST:
      return {
        ...state,
        data: action.data
      };

    case SET_CATEGORY_ISSUE_LIST_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case LOGOUT_USER:
      return issuesInitialState;

    default:
      return state;
  }
}

const actionInitialState = {
  isLoading: false
};

function actions(state = actionInitialState, action) {
  switch (action.type) {

    case SET_CATEGORY_ACTION_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case LOGOUT_USER:
      return actionInitialState;

    default:
      return state;
  }
}

export default combineReducers({
  list,
  issues,
  actions
});
