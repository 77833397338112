import { createSelector } from "reselect";
import { isCampusAdmin, isSystemAdmin } from "../utils";
import { CAMPUS_GUIDANCE_STATUS_ALL, CAMPUS_GUIDANCE_STATUS_UNPUBLISHED } from "../constants";

const getCampusList = state => state.campuses.data;
const getCurrentUser = state => state.session.currentUser;
const getCurrentIssue = state => state.issues.currentIssue.data;

export const getViewableCampusGuidanceForCurrentIssue = createSelector(
  [
    getCampusList,
    getCurrentUser,
    getCurrentIssue
  ],
  (campusList, currentUser, issue) => {
    if (!(currentUser && issue)) {
      return [];
    }

    return campusList.reduce((guidanceData, campus) => {
      const campusGuidance =
        issue.campusGuidance.items.find(guidance => guidance.campus.id === campus.id);
      const ownCampus = currentUser["custom:campus_id"] === campus.id;
      const canEdit = isCampusAdmin(currentUser) && ownCampus;

      let canView = false;
      if (campusGuidance) {
        if (campusGuidance.status === CAMPUS_GUIDANCE_STATUS_ALL) {
          canView = true;
        }

        if (ownCampus && (campusGuidance.status !== CAMPUS_GUIDANCE_STATUS_UNPUBLISHED)) {
          canView = true;
        }

        if (isSystemAdmin(currentUser)) {
          canView = true;
        }
      }

      if (canView || canEdit) {
        guidanceData.push({
          campus,
          campusGuidance,
          canEdit
        });
      }
      return guidanceData;
    }, [])

  }
);

