import {
  MISSING_ISSUE_TITLE_ERROR,
  MISSING_ISSUE_GUIDANCE_ERROR,
  MISSING_ISSUE_RATIONALE_ERROR,
  MISSING_ISSUE_AGREEMENT_LANGUAGE_ERROR,
  CAMPUS_GUIDANCE_NO_CONTENT_ERROR
} from '../constants/errors';

import { isEmptyString } from './';

export function validateCreateIssueFormData(formData) {
  const errors = [];
  const { title } = formData;

  if (isEmptyString(title)) {
    errors.push(MISSING_ISSUE_TITLE_ERROR);
  }

  return errors;
}

// TODO validate title, category, status, and tags
export function validateUpdateIssueFormData(formData, skipRawTextCheck = false) {
  const errors = [];
  const { title, rawText, agreement_language, guidance, rationale } = formData;

  if (typeof title !== "undefined" && isEmptyString(title)) {
    errors.push(MISSING_ISSUE_TITLE_ERROR);
  }

  if (typeof guidance !== "undefined"
    && (isEmptyString(guidance) || (!skipRawTextCheck && isEmptyString(rawText)))
  ) {
    errors.push(MISSING_ISSUE_GUIDANCE_ERROR);
  }

  if (typeof rationale !== "undefined" &&
    (isEmptyString(rationale) || (!skipRawTextCheck && isEmptyString(rawText)))
  ) {
    errors.push(MISSING_ISSUE_RATIONALE_ERROR);
  }

  if (typeof agreement_language !== "undefined" &&
    (isEmptyString(agreement_language) || (!skipRawTextCheck && isEmptyString(rawText)))
) {
    errors.push(MISSING_ISSUE_AGREEMENT_LANGUAGE_ERROR);
  }

  return errors;
}

export function validateCreateCampusGuidanceFormData(formData) {
  const errors = [];
  const { rawText, guidance } = formData;

  if (isEmptyString(rawText) || isEmptyString(guidance)) {
    errors.push(CAMPUS_GUIDANCE_NO_CONTENT_ERROR);
  }

  return errors;
}

export function makeIssueNameWithStatus(issue) {
  return issue.published ? issue.title : "*" + issue.title;
}
