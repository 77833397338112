import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from '../../../utils';

import wordmarkImage from './uc_wordmark_stacked_gray.png';

const WordMark = props => {
  const { className, id } = props;
  return (
    <img
      id={id}
      className={makeClassName("ucop-wordmark", className)}
      src={wordmarkImage}
      alt="University of California wordmark"
    />
  );
};

WordMark.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

export default WordMark;
