import React, { useState, Fragment, Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../components/widgets";
import { connect } from "react-redux";
import { getSortedTagListWithIssueData } from "../../../selectors";
import { makeIssueNameWithStatus, makeClassName } from "../../../utils";
import {
  TAG_CREATE_ROUTE,
  TAG_DELETE_ROUTE_PREFIX,
  TAG_EDIT_ROUTE_PREFIX,
  ISSUE_ROUTE_PREFIX
} from '../../../constants/routes';

const TagIssueList = props => {
  const [showList, setShowList] = useState(false);
  const tag = props.tag;
  const issues = tag.issues;

  const hasIssues = issues.length > 0;

  return (
    <Fragment>
      <button
        className="tag-name"
        onClick={() => setShowList(!showList)}
        aria-expanded={showList}
      >{tag.name}
        {hasIssues &&
            <i className={makeClassName(
              'fas',
              'fa-caret-down',
              showList ? 'caret-down' : 'caret-up')}
            />
        }
    </button>
      {hasIssues && showList &&
        <ul className="tag-issue-list">
          {issues.map((issue, idx) => (
            <li key={issue.id}>
              <span className="category-title">{issue.category.name + ":"}</span>
              <Link to={ISSUE_ROUTE_PREFIX + issue.id}>{makeIssueNameWithStatus(issue)}</Link>
            </li>
          ))}
        </ul>
      }
    </Fragment>
  );
};

const TagActionButtons = props => {
  const { id, name } = props;

  return (
    <Fragment>
      <Link to={TAG_EDIT_ROUTE_PREFIX + id} className="tag-button-link">
        <i
          className="fas fa-edit"
          title={`Edit ${name}`}
        />
      </Link>
      <Link to={TAG_DELETE_ROUTE_PREFIX + id} className="tag-button-link">
        <i
          className="fas fa-trash-alt"
          title={`Delete ${name}`}
        />
      </Link>
    </Fragment>
  );
};

const TAG_LIST_COLUMNS = [
  {
    name: "name",
    displayName: "Tag Name",
    displayTransformer: tag => tag.issues.length ? <TagIssueList tag={tag} /> : tag.name,
    sort: (a, b) => (a.name > b.name) ? 1 : -1,
    filter: (tag, filterTerm) => tag.name.toLowerCase().includes(filterTerm.toLowerCase()),
    defaultSort: true,
  },
  {
    name: "issueCount",
    displayName: "Issues",
    sort: (a, b) => (a.issueCount > b.issueCount) ? 1 : -1,
    descending: true
  },
  {
    name: "action",
    displayName: "Action",
    displayTransformer: TagActionButtons,
    width: 70
  }
];

const mapStateToProps = (state, props) => ({
  data: getSortedTagListWithIssueData(state)
});

class TagAdminList extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="admin-table">
        <Link to={TAG_CREATE_ROUTE} role="button">
          <i
            className="fas fa-plus-square add-item-btn"
            title="Add Tag"
          >
            <span className="btn-text">Add a Tag</span>
          </i>
        </Link>
        <Table
          caption="Tags tables"
          columns={TAG_LIST_COLUMNS}
          data={data}
          itemsPerPage={15}
          keyFunction={item => item.id}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(TagAdminList);
