/**
 * @module actions/tag-actions
 * @desc Redux actions to handle tag related things
 */
import {
  LOAD_TAG_LIST,
  SET_TAG_LIST_LOADING_STATUS,
  SET_TAG_ACTION_LOADING_STATUS
} from '../constants/action-types';

import {
  setErrors,
  setSuccessMessage,
  resetAllMessages
} from './';

import {
  makeGenericErrorMessage
} from '../constants/errors';

import {
  makeCreateTagSuccessMessage,
  makeUpdateTagSuccessMessage,
  makeDeleteTagSuccessMessage
} from '../constants/fragments';

import {
  validateCreateTagFormData,
  validateUpdateTagFormData
} from '../utils';

import {
  getTagById
} from '../selectors';

import {
  fetchTagList,
  createTag,
  updateTag,
  deleteTag
} from '../api';

import { TAG_LIST_ROUTE } from '../constants/routes';
import history from '../history';

const loadTagList = data => ({
  type: LOAD_TAG_LIST,
  data
});

const setTagListLoadingStatus = isLoading => ({
  type: SET_TAG_LIST_LOADING_STATUS,
  isLoading
});

const setTagActionLoadingStatus = isLoading => ({
  type: SET_TAG_ACTION_LOADING_STATUS,
  isLoading
});

/**
 * Makes an api call to retrieve the tag list if it isn't in the store already.
 * If successful it triggers an action to add it to the store.
 * @function
 * @param {boolean} force If this is false then the function returns early if it has been called before
 */
export const fetchAndLoadTagList = (force = false) => (dispatch, getState) => {
  const list = getState().tags.list;

  // we only need to do this once until we logout
  if (list.loaded && !force) {
    return false;
  }

  dispatch(setTagListLoadingStatus(true));
  fetchTagList().then(list => {
    dispatch(loadTagList(list));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setTagListLoadingStatus(false));
  });
};

/**
 * Makes an api call to create a new tag
 * @function
 * @param {object} formData
 * @param {string} formData.name
 */
export const attemptToCreateTag = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const tagList = getState().tags.list.data;
  if (tagList.length === 0) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'create tag', error: 'tag list not loaded'})
    ));
    return;
  }

  const currentUser = getState().session.currentUser;
  if (!(currentUser && currentUser.email)) {
    dispatch(setErrors(makeGenericErrorMessage({context: 'create tag', error: 'no current user'})));
    return;
  }

  const errors = validateCreateTagFormData(formData, tagList);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setTagActionLoadingStatus(true));
  createTag(formData).then(res => {
    history.replace(TAG_LIST_ROUTE);
    dispatch(setSuccessMessage(makeCreateTagSuccessMessage(res.name)));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadTagList(true));
    dispatch(setTagActionLoadingStatus(false));
  })
};


/**
 * Makes an api call to create a new tag from the issue page
 * @function
 * @param {object} formData
 * @param {string} formData.name
 */
export const addNewTag = formData => (dispatch, getState) => {

  const tagList = getState().tags.list.data;
  if (tagList.length === 0) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'create tag', error: 'tag list not loaded'})
    ));
    return;
  }

  const currentUser = getState().session.currentUser;
  if (!(currentUser && currentUser.email)) {
    dispatch(setErrors(makeGenericErrorMessage({context: 'create tag', error: 'no current user'})));
    return;
  }

  return createTag(formData).then(res => {
    return res;
  }).catch(errors => {
   dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadTagList(true));
    dispatch(setTagActionLoadingStatus(false));
  })
};


/**
 * Makes an api call to change a tag's name
 * If successfull it reloads the tag list. Otherwise it sets an error message.
 * @function
 * @param {object} formData
 * @param {string} formData.name
 * @param {string} formData.id
 *
 */
export const attemptToUpdateTag = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const tagList = getState().tags.list.data;

  if (tagList.length === 0) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'update tag', error: 'tag list not loaded'})
    ));
    return;
  }

  const { id } = formData;
  const tag = getTagById(id)(getState());
  if (!tag) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'update tag', error: 'unable to find tag by id'})
    ));
    return;
  }

  // if the user didn't actually change the name then we can just exit
  if (tag.name.trim() === formData.name.trim()) {
    history.replace(TAG_LIST_ROUTE);
    return;
  }

  const errors = validateUpdateTagFormData(formData, tagList);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setTagActionLoadingStatus(true));
  updateTag(formData).then(res => {
    history.replace(TAG_LIST_ROUTE);
    dispatch(setSuccessMessage(makeUpdateTagSuccessMessage(res.name)));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadTagList(true));
    dispatch(setTagActionLoadingStatus(false));
  })
};



/**
 * Makes an api call to delete a tag
 * @function
 * @param {string} tagId
 */
export const attemptToDeleteTag = tagId => (dispatch, getState) => {
  dispatch(resetAllMessages());

  dispatch(setTagActionLoadingStatus(true));
  deleteTag(tagId).then(res => {
    history.replace(TAG_LIST_ROUTE);
    dispatch(setSuccessMessage(makeDeleteTagSuccessMessage(res.name)));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadTagList(true));
    dispatch(setTagActionLoadingStatus(false));
  })
};



/**
 * Makes an api call to delete a tag from the issue page
 * @function
 * @param {string} tagId
 */
export const deleteNewTag = tagId => (dispatch, getState) => {
  dispatch(resetAllMessages());

  dispatch(setTagActionLoadingStatus(true));
  deleteTag(tagId).then(res => {
    return res;
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(fetchAndLoadTagList(true));
    dispatch(setTagActionLoadingStatus(false));
  })
};