import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../components/widgets";
import { connect } from "react-redux";
import { makeIssueNameWithStatus } from "../../../utils";
import {
  CATEGORY_ROUTE_PREFIX,
  ISSUE_ROUTE_PREFIX,
  ISSUE_CREATE_ROUTE
} from "../../../constants/routes";

const ISSUE_LIST_COLUMNS = [
  {
    name: "title",
    displayName: "Issue Name",
    displayTransformer: issue => <Link to={ISSUE_ROUTE_PREFIX + issue.id}>{makeIssueNameWithStatus(issue)}</Link>,
    sort: (a, b) => {
      if (a.published && !b.published) {
        return -1;
      }

      if (!a.published && b.published) {
        return 1;
      }

      return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
    },
    filter: (issue, filterTerm) => makeIssueNameWithStatus(issue).toLowerCase().includes(filterTerm.toLowerCase()),
    defaultSort: true
  },
  {
    name: "categoryName",
    displayName: "Category",
    displayTransformer: issue => <Link to={CATEGORY_ROUTE_PREFIX + issue.category.id}>{issue.category.name}</Link>,
    sort: (a, b) => a.category.name.toLowerCase() > b.category.name.toLowerCase() ? 1 : -1,
    filter: (issue, filterTerm) => issue.category.name.toLowerCase().includes(filterTerm.toLowerCase()),
  },
  {
    name: "status",
    displayName: "Status",
    displayTransformer: issue => issue.published ? "Published" : "Unpublished",
  }
];

const mapStateToProps = (state, props) => ({
  data: state.issues.list.data
});

class IssueAdminList extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="admin-table">
        <Link to={ISSUE_CREATE_ROUTE} role="button">
          <i
            className="fas fa-plus-square add-item-btn"
            title="Add a new issue"
          >
            <span className="btn-text">Add an Issue</span>
          </i>
        </Link>
        <Table
          caption="Categories tables"
          columns={ISSUE_LIST_COLUMNS}
          data={data}
          itemsPerPage={15}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(IssueAdminList);
