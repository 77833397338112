import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Form,
  Select,
  TextInput,
  Button
} from "../../components/widgets";

import TagSelector from './tag-selector';

import { getSortedTagList, getCategorySelectList } from "../../selectors";
import {
  fetchAndLoadCategoryList,
  fetchAndLoadTagList,
  setSectionEditStatus,
  addNewTag,
  deleteNewTag,
  setSuccessMessage,
  resetAllMessages
} from "../../actions";
import { isSystemAdmin, makeClassName } from "../../utils";

const STATUS_OPTIONS = [
  {
    name: 'Unpublished',
    value: false
  },
  {
    name: 'Published',
    value: true
  }
];

const mapStateToProps = (state, props) => {
  return {
    shouldLockForm:
      state.categories.list.isLoading ||
      state.issues.currentIssue.isUpdating ||
      state.tags.list.isLoading,
    categories: getCategorySelectList(state),
    currentUser: state.session.currentUser,
    tagList: getSortedTagList(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAndLoadCategoryList: () => dispatch(fetchAndLoadCategoryList()),
    setSectionEditStatus: (section, status) => dispatch(setSectionEditStatus(section, status)),
    fetchAndLoadTagList: () => dispatch(fetchAndLoadTagList()),
    addNewTag: (tagData) => dispatch(addNewTag(tagData)),
    deleteNewTag: (tagId) => dispatch(deleteNewTag(tagId)),
    setSuccessMessage: (successMessage) => dispatch(setSuccessMessage(successMessage)),
    resetAllMessages: () => dispatch(resetAllMessages())
  };
};

class IssueTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: props.isUnpublished && props.canEdit,
      newTags: []
    };

    this.props.setSectionEditStatus('title', this.state.editMode);
  }

  componentDidMount() {
    if (isSystemAdmin(this.props.currentUser)) {
      this.props.fetchAndLoadTagList();
    }
    this.props.fetchAndLoadCategoryList();

  }

  edit = () => {
    this.props.resetAllMessages();
    this.setState({ editMode: true });
  };

  cancel = () => {
    this.setState({ editMode: false });
    this.clearNewTags();
  };

  storeNewTag = (newTag) => {
    const currentNewTags = this.state.newTags ? this.state.newTags : [];
    this.setState({newTags: [...currentNewTags, newTag]});
  };

  // if we recieved new props that are not our previous props then this implies we either loaded a new
  // issue or saved some updates to the current issue. Either way we want to get out of edit mode
  componentDidUpdate(prevProps, prevState) {
    const oldIssue = prevProps.issue;
    const newIssue = this.props.issue;
    const oldTags = oldIssue.tags ? oldIssue.tags.items.map(t => t.id).sort().join("|") : "";
    const newTags = newIssue.tags ? newIssue.tags.items.map(t => t.id).sort().join("|") : "";
    if (
      (oldIssue.title !== newIssue.title) ||
      (oldIssue.category.id !== newIssue.category.id) ||
      (oldIssue.published !== newIssue.published) ||
      (oldTags !== newTags)
    ) {
      this.setState({ editMode: false });
    }

    this.props.setSectionEditStatus('title', this.state.editMode);
  }

  clearNewTags = () => {
    const newTags = this.state.newTags;
    for (const newTag of newTags) {
      this.props.deleteNewTag(newTag.id);
    }
    this.setState({
      newTags: []
    });
  }

  onSave = formData => {
    
    // remove newTags from this component's state
    this.setState({
      newTags: []
    });

    const { title, categoryid, tagids } = formData;
    const published = formData.published === 'true';

    const { issue } = this.props;
    // exit edit mode if we haven't made any changes
    const sameTitle = title === issue.title;
    const sameCategory = categoryid === issue.category.id;
    const sameStatus = published === issue.published;

    // format tags as sorted arrays of id strings
    // filter out empty strings from the new tag id string
    const newTagIds = tagids.split(",").filter(t => Boolean(t.trim())).sort();
    const oldTagIds = issue.tags ? issue.tags.items.map(t => t.tag.id).sort() : [];

    // convert arrays back to strings and compare them
    // since we sorted them they should be the same string if they have the same contents
    const sameTags = newTagIds.join("|") === oldTagIds.join("|");

    if (sameTitle && sameCategory && sameStatus && sameTags) {
      this.setState({ editMode: false });
      return;
    }

    if (typeof this.props.onSave === "function") {
      this.props.onSave({
        title,
        published,
        recordCategoryId: categoryid,
        tagIds: newTagIds,
        updateTags: true
      });
      this.props.setSuccessMessage('Issue ' + title + ' updated.');
    }
  }

  render() {
    const {
      isUnpublished,
      shouldLockForm,
      categories,
      issue,
      canEdit,
      currentVersion,
      previousVersion,
      tagList
    } = this.props;

    // we want to show the previous versions title if we are viewing a previous version
    const displayTitle = previousVersion ? previousVersion.title : issue.title;

    return (
      <div className={makeClassName("issue-title-container", this.state.editMode ? "edit" : "view")}>
        {this.state.editMode && (
          <div className="issue-title-edit-container">
            <Form className="issue-title-form" onSubmit={this.onSave}>
              <div className="issue-title-edit-contents">
                <div className="issue-form-fields">
                  <TextInput
                    label="Title"
                    name="title"
                    required
                    initialValue={issue.title}
                    disabled={shouldLockForm}
                  />
                  <Select
                    label="Category"
                    name="categoryid"
                    options={categories}
                    initialValue={issue.category.id}
                    required
                    disabled={shouldLockForm}
                  />
                  <Select
                    label="Status"
                    name="published"
                    options={STATUS_OPTIONS}
                    initialValue={Boolean(issue.published)}
                    required
                    disabled={shouldLockForm}
                  />
                  <TagSelector
                    tagList={tagList}
                    issueTags={issue.tags.items.map(item => item.tag)}
                    disabled={shouldLockForm}
                    addNewTag={this.props.addNewTag}
                    storeNewTag={this.storeNewTag}
                  />
                </div>
                {isUnpublished && (
                  <div className="unpublished-issue-info">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    />
                    <p>
                      This issue is currently
                      <span className="gold"> unpublished </span>and is only
                      visible to system admins.
                    </p>
                  </div>
                )}
              </div>

              {shouldLockForm ? (
                <Spinner />
              ) : (
                <div className="issue-title-button-container">
                  <Button className="btn save-section-button" type="submit">
                    Save
                  </Button>
                  <Button
                    className="btn cancel-section-button"
                    onClick={this.cancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Form>
            {
              currentVersion && !shouldLockForm &&
                <span className="last-updated-info">
                  { `Last updated by ${currentVersion.editor} on ${currentVersion.dateString} at ${currentVersion.timeString}` }
                </span>
            }
          </div>
        )}
        <Fragment>
          <h1 className="issue-title">
            {isUnpublished ? displayTitle + " (unpublished)" : displayTitle}
            <Button onClick={window.print} className="btn edit-section-button" label="print">
              <i
                className="fas fa-print"
                title="Print"
              />
            </Button>
            {canEdit && (
              <Fragment>
                <Button onClick={this.edit} className="btn edit-section-button">
                  Edit
                </Button>
              </Fragment>
            )}

            <br />
            <span className="title-desc">{issue.category.name}</span>
          </h1>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueTitle);
