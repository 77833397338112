export const MISSING_NAME_ERROR = 'Please add your first and last names';
export const SHORT_NAME_ERROR = 'Names must be at least 2 characters';
export const MISSING_CAMPUS_ERROR = 'Please select a campus';
export const MISSING_ROLE_ERROR = 'Please select a role';
export const DELETE_USER_NO_CONFIRMATION_ERROR = 'Please enter the correct email address to confirm deletion';

export const UNABLE_TO_LOAD_LIST_ERROR = 'We are unable to load the user list at this time.';

export const UNABLE_TO_UPDATE_USER_ERROR = 'We are unable to update this user at this time.'
export const UNABLE_TO_DELETE_USER_ERROR = 'We are unable to delete this user at this time.'
