/**
 * @module utils
 * @desc Helper methods and classes
 */
import {
  ROLE_SYSTEM_ADMINISTRATOR,
  ROLE_CAMPUS_ADMINISTRATOR,
  ROLE_LOCATION_ACCESS_COORDINATOR,
  ROLE_VIEWER
} from '../constants';

export * from './users';
export * from './categories';
export * from './issues';
export * from './tags';

// TODO for all util update validators: add checks to see if the updated data differs from the currtent data
// we can avoid sending requests if a user presses 'update' without changing any fields

///////////////////////////////////////////////////////////////
// ENV CHECKS /////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/**
 * Returns the name of the environment
 * @return {string} env name
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
/* Checks if we are in the production environment
 * @return {boolean} true if production
 */
export function isProd() {
  return getEnv() === "production";
}

/**
/* Checks if we are in the development environment
 * @return {boolean} true if development
 */
export function isDev() {
  return getEnv() === "development";
}

/**
/* Checks if we are in the test environment
 * @return {boolean} true if test
 */
export function isTest() {
  return getEnv() === "test";
}

///////////////////////////////////////////////////////////////
// HELPER METHODS /////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/**
 * Combines strings into a single string to use with a react className prop
 * @param {...string} names - A variable amount of names to combine
 * @return {string}
 * @example
 *  makeClassName('ucop-form', 'login-form', 'hidden')
 */
export function makeClassName(...names) {
  return names
    .map(name => (name ? name.trim() : null))
    .filter(name => name)
    .join(" ");
}

export const isSystemAdmin = currentUser =>
  currentUser && currentUser.role === ROLE_SYSTEM_ADMINISTRATOR;

export const isCampusAdmin = currentUser =>
  currentUser && currentUser.role === ROLE_CAMPUS_ADMINISTRATOR;

export const isLocationAccessCoordinator = currentUser =>
  currentUser && currentUser.role === ROLE_LOCATION_ACCESS_COORDINATOR;

export const isViewer = currentUser =>
  currentUser && currentUser.role === ROLE_VIEWER;

/**
 * @param {string} string
 * @param {boolean} includeWhiteSpace - if true then ' ' would not be considered empty
 * @return {boolean}
 */
export function isEmptyString(string = "", includeWhiteSpace = false) {
  string = includeWhiteSpace ? string : string.trim();

  return !string;
}
