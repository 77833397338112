import React, { Component } from "react";
import "./style.scss";
import {
  AuthenticatedRoute,
  PageContainer,
  PageContent,
  LeftsideMenu
} from "../../../components";

import {
  USERS_DELETE_ROUTE,
  USERS_EDIT_ROUTE,
  USERS_ROUTE
} from "../../../constants/routes";
import { Spinner } from "../../../components/widgets";
import UserList from "./list-user";
import DeleteUserModal from "./delete-user-modal";
import EditUserModal from "./edit-user-modal";

import { connect } from "react-redux";

import {
  fetchAndLoadUserList,
  fetchAndLoadCampusList,
  resetAllMessages } from "../../../actions";

import {
  ROLE_SYSTEM_ADMINISTRATOR,
  ROLE_LOCATION_ACCESS_COORDINATOR
} from "../../../constants";

import { isSystemAdmin } from "../../../utils";
import { getCurrentUser } from "../../../selectors";

const mapStateToProps = (state, props) => ({
  isLoadingUserList: state.users.list.isLoading,
  currentUser: getCurrentUser(state)
});

class Users extends Component {
  componentDidMount() {
    this.props.fetchAndLoadCampusList();
    this.props.fetchAndLoadUserList();
    this.props.resetAllMessages();
  }

  componentWillUnmount() {
    this.props.resetAllMessages();
  }

  render() {
    const { currentUser, isLoadingUserList } = this.props;
    return (
      <PageContainer className="user-management">
        <AuthenticatedRoute
          path={USERS_DELETE_ROUTE}
          component={DeleteUserModal}
          allowedRoles={[
            ROLE_SYSTEM_ADMINISTRATOR,
            ROLE_LOCATION_ACCESS_COORDINATOR
          ]}
        />
        <AuthenticatedRoute
          path={USERS_EDIT_ROUTE}
          component={EditUserModal}
          allowedRoles={[
            ROLE_SYSTEM_ADMINISTRATOR,
            ROLE_LOCATION_ACCESS_COORDINATOR
          ]}
        />
        { isSystemAdmin(currentUser) && <LeftsideMenu path={USERS_ROUTE} /> }
        <PageContent>
          {isLoadingUserList ?  <Spinner /> : <UserList /> }
        </PageContent>
      </PageContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    fetchAndLoadUserList,
    fetchAndLoadCampusList,
    resetAllMessages
  }
)(Users);
