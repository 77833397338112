/**
 * @module utils/categories
 * @desc Helper methods and validators for category forms
 */
import {
  MISSING_TAG_NAME_ERROR,
  DUPLICATE_TAG_NAME_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

import { isEmptyString } from './';

/**
 * Checks update tag form for errors
 * @param {object} formData
 * @param {string} formData.id
 * @param {string} formData.name
 * @param {object[]} tagList
 * @returns {node[]} errors
 */
export function validateUpdateTagFormData(formData, tagList) {
  const errors = [];
  const { id, name } = formData;

  if (isEmptyString(name)) {
    errors.push(MISSING_TAG_NAME_ERROR);
  } else if (tagList.find(tag => tag.name.trim() === name.trim() )) {
    errors.push(DUPLICATE_TAG_NAME_ERROR);
  }

  if (isEmptyString(id)) {
    errors.push(makeGenericErrorMessage({context: 'update tag', error: 'missing id'}));
  }

  return errors;
}


/**
 * Checks create tag form for errors
 * @param {object} formData
 * @param {string} formData.name
 * @param {object[]} tagList
 * @returns {string[]} errors
 */
export function validateCreateTagFormData(formData, tagList) {
  const errors = [];
  const { name } = formData;


  if (isEmptyString(name)) {
    errors.push(MISSING_TAG_NAME_ERROR);
  } else if (tagList.find(tag => tag.name.trim() === name.trim() )) {
    errors.push(DUPLICATE_TAG_NAME_ERROR);
  }

  return errors;
}
