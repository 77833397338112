import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { makeClassName } from '../../../utils';

class Form extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    const inputs = Array.from(event.target);
    const data = inputs.reduce((values, input) => {
      if (input.name) {
        values[input.name] = input.value;
      }
      return values;
    }, {});

    if (this.props.onSubmit) {
      this.props.onSubmit(data);
    }
  }

  render() {
    const {children, id, className } = this.props;
    return (
      <form
        id={id}
        className={makeClassName("ucop-form", className)}
        onSubmit={this.onSubmit}
      >
        {children}
      </form>
    );
  }
}

Form.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func
};

export default Form;
