/**
 * @module api/cognito
 * @desc Various functions that interact with the cognito user pool
 */

import {
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import { awsConf } from "../config/awsConfig";

import {
  UNABLE_TO_LOAD_USER_ERROR,
  makeGenericErrorMessage
} from "../constants/errors";

import { ALL_ROLES } from '../constants';

import {
  isDev
} from '../utils';

/**
 * Gets the current cognito user using the data from local storage
 * @function
 * @returns {CognitoUser}
 */
export const getCurrentCognitoUser = () => {
  const userPool = new CognitoUserPool(awsConf);
  return userPool.getCurrentUser();
};

/**
 * Gets the current cognito user's attributes.
 * @function
 * @returns {Promise<object|errors[]>}
 */
export const getCurrentCognitoUserAttributes = () =>
  getCurrentCognitoUserWithSession().then(user => {
    const { idToken } = user.signInUserSession;
    const attributes = idToken.payload;
    const groups = attributes['cognito:groups'];
    if (groups) {
      attributes.role = groups.find(group => {
        return ALL_ROLES.find(r => r === group);
      });
    }

    return attributes;
  });

if (isDev()) {
  window.getCurrentCognitoUserAttributes = () => {
    getCurrentCognitoUserAttributes().then(console.log, console.log);
  };
}

/**
 * Gets the current cognito user with up to date session info
 * @function
 * @returns {Promise<object|errors[]>}
 */
const getCurrentCognitoUserWithSession = () => new Promise((resolve, reject) => {
  const cognitoUser = getCurrentCognitoUser();
  if (!cognitoUser) {
    // TODO error handling
    reject();
    return;
  }

  cognitoUser.getSession((error, session) => {
    if (error) {
      switch (error.code) {
        default:
          reject([
            UNABLE_TO_LOAD_USER_ERROR,
            makeGenericErrorMessage({context: 'get user session', error})
          ]);
      }
      return;
    }

    resolve(cognitoUser);
  });
});
/**
 * Gets the current user's id token for use in authed api calls
 * @function
 * @returns {string|null} token
 */
export const getCurrentCognitoUserTokens = () => getCurrentCognitoUserWithSession().then(user => {
  const { idToken, accessToken } = user.signInUserSession;
  return {
    idToken: idToken.getJwtToken(),
    accessToken: accessToken.getJwtToken()
  };
});

if (isDev()) {
  window.getTokens = () => {
    getCurrentCognitoUserTokens().then(console.log, console.log);
  };
}
