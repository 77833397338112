/**
 * @module actions/issue-actions
 * @desc Redux actions to handle issue related things
 */

import {
  LOAD_ISSUE_LIST,
  SET_ISSUE_LIST_LOADING_STATUS,
  SET_SINGLE_ISSUE_UPDATING_STATUS,
  LOAD_SINGLE_ISSUE,
  SET_SINGLE_ISSUE_LOADING_STATUS,
  SET_NAV_SECTION_VISIBLE,
  SET_SECTION_EDIT_STATUS,
  LOAD_PREVIOUS_VERSION
} from '../constants/action-types';

import {
  ISSUE_ROUTE_PREFIX
} from '../constants/routes';

import {
  makeGenericErrorMessage
} from '../constants/errors';

import {
  validateCreateIssueFormData,
  validateCreateCampusGuidanceFormData,
  validateUpdateIssueFormData
} from '../utils';

import history from '../history';

import {
  setErrors,
  resetAllMessages
} from './';

import {
  fetchIssueList,
  fetchSingleIssue,
  fetchPreviousVersion,
  createIssue,
  updateIssue,
  updateCampusGuidance,
  createCampusGuidance
} from '../api';

const loadIssueList = data => ({
  type: LOAD_ISSUE_LIST,
  data
});

export const setNavSectionVisible = id => ({
  type: SET_NAV_SECTION_VISIBLE,
  visible: id
});

export const setSectionEditStatus = (section, status) => ({
  type: SET_SECTION_EDIT_STATUS,
  section,
  status
});

const setIssueListLoadingStatus = isLoading => ({
  type: SET_ISSUE_LIST_LOADING_STATUS,
  isLoading
});

const loadSingleIssue = data => ({
  type: LOAD_SINGLE_ISSUE,
  data
});

const clearCurrentIssue = () => ({
  type: LOAD_SINGLE_ISSUE,
  data: null
});

const loadPreviousVersion = previousVersion => ({
  type: LOAD_PREVIOUS_VERSION,
  previousVersion
});

export const clearPreviousVersion = () => ({
  type: LOAD_PREVIOUS_VERSION,
  previousVersion: null
});

const setSingleIssueLoadingStatus = isLoading => ({
  type: SET_SINGLE_ISSUE_LOADING_STATUS,
  isLoading
});

const setSingleIssueUpdatingStatus = isUpdating => ({
  type: SET_SINGLE_ISSUE_UPDATING_STATUS,
  isUpdating
});

/**
 * Makes an api call to retrieve the issue list.
 * If successful it triggers an action to add it to the store.
 * @function
 */
export const fetchAndLoadIssueList = () => (dispatch, getState) => {
  dispatch(setIssueListLoadingStatus(true));
  fetchIssueList().then(list => {
    dispatch(loadIssueList(list));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setIssueListLoadingStatus(false));
  });
};

/**
 * Makes an api call to retrieve a single issue
 * If successful it triggers an action to add it to the store
 * @function
 * @param {string} id
*/
export const fetchAndLoadSingleIssue = (id, versionId = null) => (dispatch, getState) => {
  // we want to clear the current issue in case we fail to fetch
  // we don't want previous issue data to be in the store
  dispatch(clearCurrentIssue());
  dispatch(clearPreviousVersion());

  dispatch(setSingleIssueLoadingStatus(true));
  fetchSingleIssue(id).then(issue => {
    dispatch(loadSingleIssue(issue));
    if (versionId) {
      return fetchPreviousVersion(versionId).then(res => dispatch(loadPreviousVersion(res)));
    }
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSingleIssueLoadingStatus(false));
  });
};

export const updateCurrentIssue = (data, skipRawTextCheck = false) => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const issue = getState().issues.currentIssue.data;
  if (!issue) {
    dispatch(setErrors(
      makeGenericErrorMessage({context: 'update issue', error: 'no current issue'})
    ));
  }

  const errors = validateUpdateIssueFormData(data, skipRawTextCheck);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  const updatedIssue = {
    ...issue,
    ...data
  };

  dispatch(setSingleIssueUpdatingStatus(true));
  updateIssue(updatedIssue).then(res => {
    dispatch(loadSingleIssue(res));
    history.replace(ISSUE_ROUTE_PREFIX + res.id);
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSingleIssueUpdatingStatus(false));
  })
};

export const attemptToCreateIssue = issueData => (dispatch, getState) => {
  dispatch(resetAllMessages());
  issueData.categoryId = issueData.categoryid;


  const errors = validateCreateIssueFormData(issueData);
  if (errors.length) {
    dispatch(setErrors(errors));
    return;
  }
  dispatch(setSingleIssueUpdatingStatus(true));
  createIssue(issueData).then(res => {
    history.replace(ISSUE_ROUTE_PREFIX + res.id);
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSingleIssueUpdatingStatus(false));
  });
};

export const createOrUpdateCampusGuidanceForCurrentIssue = data => (dispatch, getState) => {
  if (data.id) {
    dispatch(updateCampusGuidanceForCurrentIssue(data));
  } else {
    dispatch(createCampusGuidanceForCurrentIssue(data));
  }
};

const updateCampusGuidanceForCurrentIssue = data => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const errors = validateCreateCampusGuidanceFormData(data);

  if (errors.length) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setSingleIssueUpdatingStatus(true));
  updateCampusGuidance(data).then(res => {
    dispatch(loadSingleIssue(res.record));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSingleIssueUpdatingStatus(false));
  })
};

const createCampusGuidanceForCurrentIssue = data => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const errors = validateCreateCampusGuidanceFormData(data);

  if (errors.length) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setSingleIssueUpdatingStatus(true));
  createCampusGuidance(data).then(res => {
    dispatch(loadSingleIssue(res.record));
  }).catch(errors => {
    dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setSingleIssueUpdatingStatus(false));
  })
};
